
export default {
  name: 'Button',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    }
  },

  computed: {
    isIcon() {
      return !!this.icon && this.$vuetify.breakpoint.xsOnly;
    }
  }
};
