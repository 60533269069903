
import { mapGetters, mapMutations } from 'vuex';
import { prepareName } from '~/utils/helpers';

export default {
  data() {
    return {
      alerts: [],
      show: false,
      timeout: 3000,
      width: '480px'
    };
  },

  computed: {
    ...mapGetters({
      err: 'error'
    }),

    errId() {
      return this.err?.data?.title ? prepareName(this.err.data.title) : '';
    },

    isAlert() {
      return this.alerts.includes(this.errId);
    },

    detail() {
      if (this.errId) {
        const name = `err.${this.errId}.detail`;
        return this.$te(name) ? this.$t(name) : this.err.data.detail;
      } else return '';
    }
  },

  watch: {
    err(value) {
      if (value) this.show = true;
    },

    show(showed) {
      if (!showed) this.clearError();
    }
  },

  mounted() {
    // List of errors to show as alert. Get from errId = prepareName(err.data.title)
    this.alerts = ['ACLexists', 'Registered'];
  },

  methods: {
    ...mapMutations({
      clearError: 'clearError'
    }),

    finish() {
      this.clearError();
      this.show = false;
    }
  }
};
