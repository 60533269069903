
export default {
  name: 'NavMenu',

  data() {
    return {
      rootMenu: []
    };
  },

  mounted() {
    this.rootMenu = [
      ['navMenu.dashboard', 'mdi-view-dashboard-outline', 'index']
      // ['navMenu.controllers', 'mdi-router-network', 'controllers'],
      // ['navMenu.devices', 'mdi-motion-sensor', 'devices']
    ];
  }
};
