/* eslint-disable no-console */
import { findItemNested, getParent, getOrgId, logger } from '~/utils/helpers';

export const state = () => ({
  currentLoc: {
    id: null,
    type: 'node',
    attributes: {
      name: ''
    }
  },
  locList: []
});

export const getters = {
  getCurrent: (s) => s.currentLoc,
  getList: (s) => s.locList
};

export const mutations = {
  setCurrent(state, currentLoc) {
    if (
      Object.keys(currentLoc).length === 0 &&
      currentLoc.constructor === Object
    ) {
      // Set default values
      state.currentLoc = {
        id: null,
        type: '',
        attributes: {
          name: ''
        }
      };
    } else {
      state.currentLoc = currentLoc;
    }
    logger('<[ STORE ]>', 'SET CURR NODE', state.currentLoc.id);
  },

  setList(state, locList) {
    state.locList = [...locList];
    logger('<[ STORE ]>', 'SET NODE LIST', locList);
  }
};

export const actions = {
  // Load whole list of nodes
  async loadList({ commit }, orgId) {
    try {
      const include = 'devices,devstate';
      const options = { include };
      const api = this.$lisaAPI.location;
      const res = await api.list(orgId, options);
      if (res.status === 'ok') {
        const list = res.data;
        // logger('<[ STORE ]>', 'LOAD LOC LIST', list);
        await commit('setList', list);
      } else {
        await commit('setList', []);
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Location',
            errorText: 'node.errorMsg.failGetLoc'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Location/loadList', error.message);
    }
  },

  // Load a branch of location tree filtered by node id
  async filter({ commit }, { orgId, depth, include, filter }) {
    try {
      const include = 'devices,devstate';
      const options = { depth, include, filter };
      const api = this.$lisaAPI.location;
      const res = await api.list(orgId, options);
      if (res.status === 'ok') {
        const loc = res.data[0];
        logger('<[ STORE ]>', 'FILTER', loc);
        await commit('setCurrent', loc);
      } else {
        await commit('setCurrent', {});
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Location',
            errorText: 'node.errorMsg.failGetLoc'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Location/filter', error.message);
    }
  },

  // Commit setCurrent mutation to set current node
  async current({ commit }, node) {
    await commit('setCurrent', node);
  },

  // Find node by id in the list of locations
  async find({ dispatch, getters }, id) {
    if (getters.getCurrent.id === id) return;
    const node = findItemNested(getters.getList, id, 'children');
    if (node) {
      logger('<[ STORE ]>', 'FOUND NODE', node);
      await dispatch('current', node);
      await dispatch('acl/filter', node.id, { root: true });
    } else {
      logger('<[ STORE ]>', 'FOUND', 'NOTHING');
    }
  },

  // Location info
  async info({ commit }, locId) {
    try {
      const orgId = getOrgId(locId);
      const api = this.$lisaAPI.location;
      const res = await api.info(orgId, locId);
      if (res.status === 'ok') {
        const loc = res.data[0];
        logger('<[ STORE ]>', 'LOCATION INFO', loc.id);
        await commit('setCurrent', loc);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Location',
            errorText: 'node.errorMsg.failGetLoc'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Location/info', error.message);
    }
  },

  // Create a new location
  async create({ dispatch, commit }, { nodeId, name }) {
    try {
      let loc = {};
      const orgId = getOrgId(nodeId);
      const api = this.$lisaAPI.location;
      const res = await api.create(orgId, nodeId, name);
      if (res.status === 'ok' && res.data) {
        loc = res.data[0];
        logger('<[ STORE ]>', 'CREATE NODE', loc.id);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Location',
            errorText: 'node.errorMsg.failAddLoc'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('loadList', orgId);
      await dispatch('find', loc ? loc.id : nodeId);
    } catch (error) {
      console.error('Store/Location/create', error.message);
    }
  },

  // Update a location
  async update({ dispatch, commit }, { nodeId, name }) {
    try {
      const orgId = getOrgId(nodeId);
      const api = this.$lisaAPI.location;
      const res = await api.update(orgId, nodeId, name);
      if (res.status === 'ok') {
        logger('<[ STORE ]>', 'UPDATE NODE', nodeId);
        await commit('setCurrent', {});
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Location',
            errorText: 'node.errorMsg.failUpdLoc'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('loadList', orgId);
      await dispatch('find', nodeId);
    } catch (error) {
      console.error('Store/Location/update', error.message);
    }
  },

  // Delete location
  async delete({ dispatch, commit }, locId) {
    try {
      const orgId = getOrgId(locId);
      const parentId = getParent(locId);
      const api = this.$lisaAPI.location;
      const res = await api.delete(orgId, locId);
      if (res.status === 'ok') {
        logger('<[ STORE ]>', 'DELETE LOC', locId);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Location',
            errorText: 'node.errorMsg.failDelLoc'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('loadList', orgId);
      await dispatch('find', parentId);
      await dispatch('acl/list', orgId, { root: true });
    } catch (error) {
      console.error('Store/Location/delete', error.message);
    }
  }
};
