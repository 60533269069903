
import { mapGetters } from 'vuex';
import Union from '@/components/Union.vue';
import Pack from '@/components/Pack.vue';
import { logger } from '~/utils/helpers';

export default {
  name: 'GroupTable',

  props: {
    table: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      menu: []
    };
  },

  computed: {
    ...mapGetters({
      currentOrg: 'organization/getCurrent'
    }),

    columns() {
      if (this.$vuetify.breakpoint.smAndUp)
        return [
          { name: 'id', class: 'group-id', render: this.asIs },
          { name: 'owner', class: 'group-owner', render: this.owner },
          { name: 'group', class: 'group-org', render: this.group }
        ];
      else
        return [
          { name: 'id', class: 'group-id', render: this.asIs },
          { name: 'group_pack', class: 'group-pack', render: this.pack }
        ];
    },

    isMenuItemOff() {
      return this.table.map((line) => {
        return this.menu.map(([title, icon, func]) => {
          switch (func) {
            case 'select':
              return false;
            case 'edit':
              return !line.meta.isowner;
            case 'del':
              return !line.meta.isowner;
            default:
              return true;
          }
        });
      });
    },

    padding() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 4;
        case 'sm':
          return 6;
        case 'md':
          return 8;
        case 'lg':
          return 10;
        case 'xl':
          return 12;
        default:
          return 16;
      }
    }
  },

  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      this.setMenu();
    }
  },

  mounted() {
    this.setMenu();
  },

  methods: {
    setMenu() {
      this.menu = [
        [this.$t('group.menu.select'), 'mdi-target', 'select'],
        [this.$t('group.menu.change'), 'mdi-home-edit-outline', 'edit'],
        [this.$t('group.menu.remove'), 'mdi-home-remove-outline', 'del']
      ];
    },

    asIs(item, name) {
      return {
        type: 'text',
        payload: `${item[name]}`
      };
    },
    owner(item, name) {
      return {
        type: 'component',
        component: Union,
        props: {
          icon: item.relationships.owner.attributes.avatar,
          title: item.relationships.owner.attributes.nickname,
          append: '',
          subtitle: item.relationships.owner.attributes.email
        }
      };
    },
    group(item, name) {
      const avatar = item.attributes.avatar
        ? item.attributes.avatar
        : item.attributes.type === 'home'
        ? 'mdi-home'
        : 'mdi-domain';
      return {
        type: 'component',
        component: Union,
        props: {
          icon: avatar,
          title: item.attributes.name,
          append: item.meta.isowner ? 'mdi-license' : '',
          subtitle: item.attributes.description
        }
      };
    },
    pack(item, name) {
      return {
        type: 'component',
        component: Pack,
        props: {
          data: [this.owner(item, name), this.group(item, name)]
        }
      };
    },

    add(value) {
      this.$emit('add', value);
    },

    response(value) {
      logger('<G R O U P>', 'RESPONSE', value);
    }
  }
};
