export async function unWrap(response) {
  const json = await response.json();
  const { ok, status, statusText } = response;
  return {
    json,
    ok,
    status,
    statusText
  };
}

export function getErrorResponse(error) {
  const [e] = error.response.data.errors;
  return {
    status: 'error',
    code: e.status,
    title: e.title,
    detail: e.detail,
    statusText: error.message || {}
  };
}
