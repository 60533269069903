
import { mapActions } from 'vuex';
import { logger } from '~/utils/helpers';

export default {
  name: 'DelGroup',

  props: {
    enable: Boolean,
    group: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      hasChild: null,
      width: '480px'
    };
  },

  computed: {
    texts() {
      return {
        header: this.$t('group.del.header'),
        body:
          this.$t('group.del.bodyHead') +
          ` "${this.group?.attributes.name}" (${this.group?.id}) ` +
          this.$t('group.del.bodyTail'),
        reject: this.$t('iface.btn.reject'),
        accept: this.$t('group.del.accept')
      };
    }
  },

  watch: {
    enable(value) {
      if (!value) return;
      const depth = 1;
      this.$lisaAPI.location.list(this.group?.id, { depth }).then((res) => {
        if (res.status === 'ok') this.hasChild = !!res.data[0].children.length;
      });
    }
  },

  methods: {
    ...mapActions({
      deleteOrg: 'organization/delete'
    }),

    submit() {
      this.delete();
    },
    delete() {
      logger(':-: :-: :-:', 'DELETE GROUP', this.group.id);
      this.deleteOrg(this.group.id);
      this.emit('close');
    },

    emit(e) {
      if (e) this.$emit(e);
    }
  }
};
