// https://blog.lichter.io/posts/nuxt-api-call-organization-and-decoupling/
// https://serversideup.net/using-axios-to-make-api-requests-with-vuejs/
// https://www.youtube.com/watch?v=p4fX3i5h5uM

import AclAPI from '@/api/acl';
import AuthAPI from '@/api/auth';
import BackAPI from '@/api/back';
import DeviceAPI from '@/api/device';
import HelperAPI from '@/api/helper';
import NodeAPI from '@/api/location';
import GroupAPI from '@/api/org';
import RuleAPI from '@/api/rule';
import UserAPI from '@/api/user';
import VirtualAPI from '@/api/virtual';
// import ClientAPI from '@/api/auth';
// import ControllerAPI from '@/api/auth';
import WeatherAPI from '@/api/weather';
import MLVideos from '@/api/mlvideos';

export default function ({ $axios }, inject) {
  const lisaAPI = {
    // https://kp-staging.lanet.house/public/#api-ACL
    acl: AclAPI($axios),
    // https://kp-staging.lanet.house/public/#api-Auth
    auth: AuthAPI($axios),
    // https://kp-staging.lanet.house/public/#api-Back
    back: BackAPI($axios),
    // https://kp-staging.lanet.house/public/#api-DeviceBack
    device: DeviceAPI($axios),
    // https://kp-staging.lanet.house/public/#api-Helper
    helper: HelperAPI($axios),
    // https://kp-staging.lanet.house/public/#api-Location
    location: NodeAPI($axios),
    // https://kp-staging.lanet.house/public/#api-Orgs
    org: GroupAPI($axios),
    // https://kp-staging.lanet.house/public/#api-Rules
    rule: RuleAPI($axios),
    // https://kp-staging.lanet.house/public/#api-Me
    user: UserAPI($axios),
    // https://kp-staging.lanet.house/public/#api-VirtualDevice
    virtual: VirtualAPI($axios),
    // https://kp-staging.lanet.house/public/#api-Client
    // client: ClientAPI($axios),
    // https://kp-staging.lanet.house/public/#api-Controller
    // controller: ControllerAPI($axios),
    // https://openweathermap.org/api
    weather: WeatherAPI($axios),
    // Work with videos and bounding ML boxes
    mlvideos: MLVideos($axios)
  };

  inject('lisaAPI', lisaAPI);
}
