
export default {
  name: 'GroupAvatar',

  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    avatar() {
      const item = this.item.attributes;
      if (item.avatar) return item.avatar;
      if (item.type === 'home') return 'mdi-home';
      else return 'mdi-domain';
    }
  }
};
