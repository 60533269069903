
export default {
  name: 'None',

  props: {},

  data() {
    return {};
  }
};
