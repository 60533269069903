import Cookie from 'js-cookie';
import CookieH from 'cookie';

// NOTE: Перевіряємо доступ до ресурсу на основі наявності cookie 'sid' з ідентифікатором сесії
export default function ({ store, redirect, req, localePath, route }) {
  let sid = null;

  if (process.server) {
    sid = CookieH.parse(req.headers.cookie || '')._lisa_sid || null;
  }
  if (process.client) {
    sid = Cookie.get('_lisa_sid') || null;
  }

  store.commit('setToken', sid);

  if (sid === null && route.path !== localePath('auth'))
    return redirect(localePath('auth'));

  if (sid !== null && route.path === localePath('auth'))
    return redirect(localePath('index'));
}
