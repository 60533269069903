import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=f47515bc"
import script from "./Table.vue?vue&type=script&lang=js"
export * from "./Table.vue?vue&type=script&lang=js"
import style0 from "./Table.vue?vue&type=style&index=0&id=f47515bc&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Table: require('/builds/iot/controller/lisa-back-html/components/Table.vue').default,CardNone: require('/builds/iot/controller/lisa-back-html/components/Card/None.vue').default})
