
export default {
  name: 'Theme',

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },

    isIcon() {
      return !this.$vuetify.breakpoint.xsOnly;
    }
  },

  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }
};
