// eslint-disable-next-line no-unused-vars
import { unWrap, getErrorResponse } from '~/utils/apiUtils';

export default ($axios) => ({
  // https://openweathermap.org/weather-conditions#Weather-Condition-Codes-2
  // Weather Conditions codes and icons

  async getCurrent() {
    try {
      let res = await $axios.$get('/v1/me');
      // const res = await $axios.$get(
      //   'https://api.openweathermap.org/data/2.5/weather',
      //   {
      //     params: {
      //       lat: 50.61544806060508,
      //       lon: 26.254123360630715,
      //       units: 'metric',
      //       lang: 'ua',
      //       appid: '651615ba8a82e332f8472a4af2241e9a'
      //     }
      //   }
      // );
      res = {
        coord: {
          lon: 26.2541,
          lat: 50.6154
        },
        weather: [
          {
            id: 800,
            main: 'Clear',
            description: 'clear sky',
            icon: '01d'
          }
        ],
        base: 'stations',
        main: {
          temp: 14,
          feels_like: 12.51,
          temp_min: 12.77,
          temp_max: 14.22,
          pressure: 1018,
          humidity: 35
        },
        visibility: 10000,
        wind: {
          speed: 6,
          deg: 220
        },
        clouds: {
          all: 0
        },
        dt: 1634308736,
        sys: {
          type: 1,
          id: 8912,
          country: 'UA',
          sunrise: 1634272649,
          sunset: 1634311422
        },
        timezone: 10800,
        id: 695594,
        name: 'Rivne',
        cod: 200
      };

      return {
        status: 'ok',
        res
      };
      // throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },
  // https://openweathermap.org/api/one-call-api
  // One Call API
  async getForecast() {
    try {
      let res = await $axios.$get('/v1/me');
      // 'https://api.openweathermap.org/data/2.5/onecall',
      // {
      //   params: {
      //     lat: 50.61544806060508,
      //     lon: 26.254123360630715,
      //     exclude: 'current',
      //     appid: '651615ba8a82e332f8472a4af2241e9a'
      //   }
      // }
      // );
      res = {
        lat: 50.6154,
        lon: 26.2541,
        timezone: 'Europe/Kiev',
        timezone_offset: 10800,
        current: {
          dt: 1634309536,
          sunrise: 1634272649,
          sunset: 1634311422,
          temp: 13.08,
          feels_like: 11.44,
          pressure: 1018,
          humidity: 38,
          dew_point: -0.8,
          uvi: 0,
          clouds: 0,
          visibility: 10000,
          wind_speed: 4,
          wind_deg: 220,
          weather: [
            {
              id: 800,
              main: 'Clear',
              description: 'clear sky',
              icon: '01d'
            }
          ]
        },
        minutely: [
          {
            dt: 1634309580,
            precipitation: 0
          },
          {
            dt: 1634309640,
            precipitation: 0
          },
          {
            dt: 1634309700,
            precipitation: 0
          },
          {
            dt: 1634309760,
            precipitation: 0
          },
          {
            dt: 1634309820,
            precipitation: 0
          },
          {
            dt: 1634309880,
            precipitation: 0
          },
          {
            dt: 1634309940,
            precipitation: 0
          },
          {
            dt: 1634310000,
            precipitation: 0
          },
          {
            dt: 1634310060,
            precipitation: 0
          },
          {
            dt: 1634310120,
            precipitation: 0
          },
          {
            dt: 1634310180,
            precipitation: 0
          },
          {
            dt: 1634310240,
            precipitation: 0
          },
          {
            dt: 1634310300,
            precipitation: 0
          },
          {
            dt: 1634310360,
            precipitation: 0
          },
          {
            dt: 1634310420,
            precipitation: 0
          },
          {
            dt: 1634310480,
            precipitation: 0
          },
          {
            dt: 1634310540,
            precipitation: 0
          },
          {
            dt: 1634310600,
            precipitation: 0
          },
          {
            dt: 1634310660,
            precipitation: 0
          },
          {
            dt: 1634310720,
            precipitation: 0
          },
          {
            dt: 1634310780,
            precipitation: 0
          },
          {
            dt: 1634310840,
            precipitation: 0
          },
          {
            dt: 1634310900,
            precipitation: 0
          },
          {
            dt: 1634310960,
            precipitation: 0
          },
          {
            dt: 1634311020,
            precipitation: 0
          },
          {
            dt: 1634311080,
            precipitation: 0
          },
          {
            dt: 1634311140,
            precipitation: 0
          },
          {
            dt: 1634311200,
            precipitation: 0
          },
          {
            dt: 1634311260,
            precipitation: 0
          },
          {
            dt: 1634311320,
            precipitation: 0
          },
          {
            dt: 1634311380,
            precipitation: 0
          },
          {
            dt: 1634311440,
            precipitation: 0
          },
          {
            dt: 1634311500,
            precipitation: 0
          },
          {
            dt: 1634311560,
            precipitation: 0
          },
          {
            dt: 1634311620,
            precipitation: 0
          },
          {
            dt: 1634311680,
            precipitation: 0
          },
          {
            dt: 1634311740,
            precipitation: 0
          },
          {
            dt: 1634311800,
            precipitation: 0
          },
          {
            dt: 1634311860,
            precipitation: 0
          },
          {
            dt: 1634311920,
            precipitation: 0
          },
          {
            dt: 1634311980,
            precipitation: 0
          },
          {
            dt: 1634312040,
            precipitation: 0
          },
          {
            dt: 1634312100,
            precipitation: 0
          },
          {
            dt: 1634312160,
            precipitation: 0
          },
          {
            dt: 1634312220,
            precipitation: 0
          },
          {
            dt: 1634312280,
            precipitation: 0
          },
          {
            dt: 1634312340,
            precipitation: 0
          },
          {
            dt: 1634312400,
            precipitation: 0
          },
          {
            dt: 1634312460,
            precipitation: 0
          },
          {
            dt: 1634312520,
            precipitation: 0
          },
          {
            dt: 1634312580,
            precipitation: 0
          },
          {
            dt: 1634312640,
            precipitation: 0
          },
          {
            dt: 1634312700,
            precipitation: 0
          },
          {
            dt: 1634312760,
            precipitation: 0
          },
          {
            dt: 1634312820,
            precipitation: 0
          },
          {
            dt: 1634312880,
            precipitation: 0
          },
          {
            dt: 1634312940,
            precipitation: 0
          },
          {
            dt: 1634313000,
            precipitation: 0
          },
          {
            dt: 1634313060,
            precipitation: 0
          },
          {
            dt: 1634313120,
            precipitation: 0
          },
          {
            dt: 1634313180,
            precipitation: 0
          }
        ],
        hourly: [
          {
            dt: 1634306400,
            temp: 13.06,
            feels_like: 11.47,
            pressure: 1018,
            humidity: 40,
            dew_point: -0.19,
            uvi: 0.36,
            clouds: 15,
            visibility: 10000,
            wind_speed: 3.67,
            wind_deg: 221,
            wind_gust: 6.38,
            weather: [
              {
                id: 801,
                main: 'Clouds',
                description: 'few clouds',
                icon: '02d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634310000,
            temp: 13.08,
            feels_like: 11.44,
            pressure: 1018,
            humidity: 38,
            dew_point: -0.8,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 2.93,
            wind_deg: 207,
            wind_gust: 3.24,
            weather: [
              {
                id: 800,
                main: 'Clear',
                description: 'clear sky',
                icon: '01d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634313600,
            temp: 12.21,
            feels_like: 10.56,
            pressure: 1018,
            humidity: 41,
            dew_point: -0.57,
            uvi: 0,
            clouds: 15,
            visibility: 10000,
            wind_speed: 3.39,
            wind_deg: 193,
            wind_gust: 3.93,
            weather: [
              {
                id: 801,
                main: 'Clouds',
                description: 'few clouds',
                icon: '02n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634317200,
            temp: 11.01,
            feels_like: 9.34,
            pressure: 1018,
            humidity: 45,
            dew_point: -0.4,
            uvi: 0,
            clouds: 27,
            visibility: 10000,
            wind_speed: 3.64,
            wind_deg: 192,
            wind_gust: 7.13,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634320800,
            temp: 9.6,
            feels_like: 7.72,
            pressure: 1018,
            humidity: 48,
            dew_point: -0.76,
            uvi: 0,
            clouds: 35,
            visibility: 10000,
            wind_speed: 3.57,
            wind_deg: 192,
            wind_gust: 6.85,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634324400,
            temp: 8.14,
            feels_like: 5.91,
            pressure: 1018,
            humidity: 53,
            dew_point: -0.76,
            uvi: 0,
            clouds: 46,
            visibility: 10000,
            wind_speed: 3.63,
            wind_deg: 192,
            wind_gust: 7.47,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634328000,
            temp: 6.34,
            feels_like: 3.42,
            pressure: 1018,
            humidity: 60,
            dew_point: -0.91,
            uvi: 0,
            clouds: 37,
            visibility: 10000,
            wind_speed: 4.16,
            wind_deg: 189,
            wind_gust: 10.96,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634331600,
            temp: 6.11,
            feels_like: 3.24,
            pressure: 1018,
            humidity: 67,
            dew_point: 0.44,
            uvi: 0,
            clouds: 49,
            visibility: 10000,
            wind_speed: 3.97,
            wind_deg: 194,
            wind_gust: 11.41,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634335200,
            temp: 5.6,
            feels_like: 2.82,
            pressure: 1017,
            humidity: 74,
            dew_point: 1.18,
            uvi: 0,
            clouds: 41,
            visibility: 10000,
            wind_speed: 3.61,
            wind_deg: 198,
            wind_gust: 10.38,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634338800,
            temp: 5.22,
            feels_like: 2.46,
            pressure: 1017,
            humidity: 78,
            dew_point: 1.57,
            uvi: 0,
            clouds: 35,
            visibility: 10000,
            wind_speed: 3.46,
            wind_deg: 206,
            wind_gust: 9.53,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634342400,
            temp: 4.89,
            feels_like: 2.15,
            pressure: 1017,
            humidity: 81,
            dew_point: 1.79,
            uvi: 0,
            clouds: 30,
            visibility: 10000,
            wind_speed: 3.32,
            wind_deg: 214,
            wind_gust: 6.98,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634346000,
            temp: 4.54,
            feels_like: 1.64,
            pressure: 1017,
            humidity: 83,
            dew_point: 1.73,
            uvi: 0,
            clouds: 7,
            visibility: 10000,
            wind_speed: 3.44,
            wind_deg: 216,
            wind_gust: 6.39,
            weather: [
              {
                id: 800,
                main: 'Clear',
                description: 'clear sky',
                icon: '01n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634349600,
            temp: 4.25,
            feels_like: 1.24,
            pressure: 1016,
            humidity: 81,
            dew_point: 1.29,
            uvi: 0,
            clouds: 8,
            visibility: 10000,
            wind_speed: 3.51,
            wind_deg: 219,
            wind_gust: 6.42,
            weather: [
              {
                id: 800,
                main: 'Clear',
                description: 'clear sky',
                icon: '01n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634353200,
            temp: 4.1,
            feels_like: 1.1,
            pressure: 1016,
            humidity: 79,
            dew_point: 0.58,
            uvi: 0,
            clouds: 7,
            visibility: 10000,
            wind_speed: 3.46,
            wind_deg: 227,
            wind_gust: 7.19,
            weather: [
              {
                id: 800,
                main: 'Clear',
                description: 'clear sky',
                icon: '01n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634356800,
            temp: 4.04,
            feels_like: 0.97,
            pressure: 1016,
            humidity: 76,
            dew_point: 0.18,
            uvi: 0,
            clouds: 9,
            visibility: 10000,
            wind_speed: 3.54,
            wind_deg: 232,
            wind_gust: 8.62,
            weather: [
              {
                id: 800,
                main: 'Clear',
                description: 'clear sky',
                icon: '01n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634360400,
            temp: 4.45,
            feels_like: 1.46,
            pressure: 1016,
            humidity: 75,
            dew_point: 0.4,
            uvi: 0,
            clouds: 23,
            visibility: 10000,
            wind_speed: 3.55,
            wind_deg: 230,
            wind_gust: 8.14,
            weather: [
              {
                id: 801,
                main: 'Clouds',
                description: 'few clouds',
                icon: '02d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634364000,
            temp: 6.92,
            feels_like: 4.39,
            pressure: 1017,
            humidity: 70,
            dew_point: 1.7,
            uvi: 0.12,
            clouds: 35,
            visibility: 10000,
            wind_speed: 3.68,
            wind_deg: 244,
            wind_gust: 9.03,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634367600,
            temp: 8.17,
            feels_like: 5.88,
            pressure: 1017,
            humidity: 69,
            dew_point: 2.65,
            uvi: 0.52,
            clouds: 100,
            visibility: 10000,
            wind_speed: 3.76,
            wind_deg: 253,
            wind_gust: 8.59,
            weather: [
              {
                id: 804,
                main: 'Clouds',
                description: 'overcast clouds',
                icon: '04d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634371200,
            temp: 9.4,
            feels_like: 7.22,
            pressure: 1017,
            humidity: 68,
            dew_point: 3.78,
            uvi: 1.05,
            clouds: 100,
            visibility: 10000,
            wind_speed: 4.1,
            wind_deg: 266,
            wind_gust: 7.21,
            weather: [
              {
                id: 804,
                main: 'Clouds',
                description: 'overcast clouds',
                icon: '04d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634374800,
            temp: 10.37,
            feels_like: 9.24,
            pressure: 1017,
            humidity: 68,
            dew_point: 4.72,
            uvi: 1.56,
            clouds: 100,
            visibility: 10000,
            wind_speed: 4.44,
            wind_deg: 281,
            wind_gust: 6.66,
            weather: [
              {
                id: 804,
                main: 'Clouds',
                description: 'overcast clouds',
                icon: '04d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634378400,
            temp: 12.68,
            feels_like: 11.52,
            pressure: 1017,
            humidity: 58,
            dew_point: 4.48,
            uvi: 1.52,
            clouds: 82,
            visibility: 10000,
            wind_speed: 4.59,
            wind_deg: 288,
            wind_gust: 6.07,
            weather: [
              {
                id: 803,
                main: 'Clouds',
                description: 'broken clouds',
                icon: '04d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634382000,
            temp: 13.64,
            feels_like: 12.34,
            pressure: 1017,
            humidity: 49,
            dew_point: 2.96,
            uvi: 1.46,
            clouds: 66,
            visibility: 10000,
            wind_speed: 5.14,
            wind_deg: 288,
            wind_gust: 6.36,
            weather: [
              {
                id: 803,
                main: 'Clouds',
                description: 'broken clouds',
                icon: '04d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634385600,
            temp: 13.67,
            feels_like: 12.32,
            pressure: 1017,
            humidity: 47,
            dew_point: 2.44,
            uvi: 1.1,
            clouds: 57,
            visibility: 10000,
            wind_speed: 5.44,
            wind_deg: 291,
            wind_gust: 6.69,
            weather: [
              {
                id: 803,
                main: 'Clouds',
                description: 'broken clouds',
                icon: '04d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634389200,
            temp: 13.4,
            feels_like: 12.05,
            pressure: 1017,
            humidity: 48,
            dew_point: 2.52,
            uvi: 0.33,
            clouds: 11,
            visibility: 10000,
            wind_speed: 5.29,
            wind_deg: 293,
            wind_gust: 6.55,
            weather: [
              {
                id: 801,
                main: 'Clouds',
                description: 'few clouds',
                icon: '02d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634392800,
            temp: 12.5,
            feels_like: 11.22,
            pressure: 1017,
            humidity: 54,
            dew_point: 3.32,
            uvi: 0.14,
            clouds: 14,
            visibility: 10000,
            wind_speed: 4.43,
            wind_deg: 299,
            wind_gust: 6.4,
            weather: [
              {
                id: 801,
                main: 'Clouds',
                description: 'few clouds',
                icon: '02d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634396400,
            temp: 9.93,
            feels_like: 7.92,
            pressure: 1018,
            humidity: 65,
            dew_point: 3.48,
            uvi: 0,
            clouds: 14,
            visibility: 10000,
            wind_speed: 4,
            wind_deg: 311,
            wind_gust: 7.17,
            weather: [
              {
                id: 801,
                main: 'Clouds',
                description: 'few clouds',
                icon: '02d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634400000,
            temp: 7.75,
            feels_like: 5.02,
            pressure: 1019,
            humidity: 76,
            dew_point: 3.78,
            uvi: 0,
            clouds: 19,
            visibility: 10000,
            wind_speed: 4.45,
            wind_deg: 319,
            wind_gust: 9.36,
            weather: [
              {
                id: 801,
                main: 'Clouds',
                description: 'few clouds',
                icon: '02n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634403600,
            temp: 6.27,
            feels_like: 3.57,
            pressure: 1020,
            humidity: 84,
            dew_point: 3.8,
            uvi: 0,
            clouds: 26,
            visibility: 10000,
            wind_speed: 3.73,
            wind_deg: 315,
            wind_gust: 9.04,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634407200,
            temp: 5.22,
            feels_like: 2.72,
            pressure: 1020,
            humidity: 88,
            dew_point: 3.31,
            uvi: 0,
            clouds: 25,
            visibility: 10000,
            wind_speed: 3.07,
            wind_deg: 307,
            wind_gust: 7.72,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634410800,
            temp: 5.25,
            feels_like: 2.71,
            pressure: 1021,
            humidity: 85,
            dew_point: 2.84,
            uvi: 0,
            clouds: 14,
            visibility: 10000,
            wind_speed: 3.13,
            wind_deg: 304,
            wind_gust: 5.32,
            weather: [
              {
                id: 801,
                main: 'Clouds',
                description: 'few clouds',
                icon: '02n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634414400,
            temp: 6.26,
            feels_like: 3.73,
            pressure: 1021,
            humidity: 77,
            dew_point: 2.3,
            uvi: 0,
            clouds: 38,
            visibility: 10000,
            wind_speed: 3.44,
            wind_deg: 307,
            wind_gust: 4.64,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634418000,
            temp: 7.31,
            feels_like: 4.88,
            pressure: 1021,
            humidity: 68,
            dew_point: 1.67,
            uvi: 0,
            clouds: 55,
            visibility: 10000,
            wind_speed: 3.66,
            wind_deg: 309,
            wind_gust: 5.09,
            weather: [
              {
                id: 803,
                main: 'Clouds',
                description: 'broken clouds',
                icon: '04n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634421600,
            temp: 7.72,
            feels_like: 5.44,
            pressure: 1021,
            humidity: 64,
            dew_point: 1.15,
            uvi: 0,
            clouds: 65,
            visibility: 10000,
            wind_speed: 3.55,
            wind_deg: 304,
            wind_gust: 4.93,
            weather: [
              {
                id: 803,
                main: 'Clouds',
                description: 'broken clouds',
                icon: '04n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634425200,
            temp: 7.48,
            feels_like: 5.4,
            pressure: 1021,
            humidity: 64,
            dew_point: 0.9,
            uvi: 0,
            clouds: 70,
            visibility: 10000,
            wind_speed: 3.13,
            wind_deg: 293,
            wind_gust: 4.11,
            weather: [
              {
                id: 803,
                main: 'Clouds',
                description: 'broken clouds',
                icon: '04n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634428800,
            temp: 7.14,
            feels_like: 5.31,
            pressure: 1021,
            humidity: 65,
            dew_point: 0.92,
            uvi: 0,
            clouds: 74,
            visibility: 10000,
            wind_speed: 2.67,
            wind_deg: 268,
            wind_gust: 4.52,
            weather: [
              {
                id: 803,
                main: 'Clouds',
                description: 'broken clouds',
                icon: '04n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634432400,
            temp: 6.2,
            feels_like: 3.95,
            pressure: 1021,
            humidity: 71,
            dew_point: 1.13,
            uvi: 0,
            clouds: 95,
            visibility: 10000,
            wind_speed: 2.99,
            wind_deg: 263,
            wind_gust: 6.02,
            weather: [
              {
                id: 804,
                main: 'Clouds',
                description: 'overcast clouds',
                icon: '04n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634436000,
            temp: 4.84,
            feels_like: 2.13,
            pressure: 1021,
            humidity: 78,
            dew_point: 1.21,
            uvi: 0,
            clouds: 75,
            visibility: 10000,
            wind_speed: 3.26,
            wind_deg: 262,
            wind_gust: 5.94,
            weather: [
              {
                id: 803,
                main: 'Clouds',
                description: 'broken clouds',
                icon: '04n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634439600,
            temp: 3.43,
            feels_like: 0.51,
            pressure: 1021,
            humidity: 86,
            dew_point: 1.21,
            uvi: 0,
            clouds: 54,
            visibility: 10000,
            wind_speed: 3.15,
            wind_deg: 264,
            wind_gust: 5.09,
            weather: [
              {
                id: 803,
                main: 'Clouds',
                description: 'broken clouds',
                icon: '04n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634443200,
            temp: 2.99,
            feels_like: -0.03,
            pressure: 1021,
            humidity: 87,
            dew_point: 1.06,
            uvi: 0,
            clouds: 44,
            visibility: 10000,
            wind_speed: 3.15,
            wind_deg: 263,
            wind_gust: 5.21,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03n'
              }
            ],
            pop: 0
          },
          {
            dt: 1634446800,
            temp: 2.82,
            feels_like: -0.17,
            pressure: 1022,
            humidity: 87,
            dew_point: 0.72,
            uvi: 0,
            clouds: 38,
            visibility: 10000,
            wind_speed: 3.07,
            wind_deg: 258,
            wind_gust: 5.39,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634450400,
            temp: 4.44,
            feels_like: 2,
            pressure: 1022,
            humidity: 78,
            dew_point: 0.86,
            uvi: 0.16,
            clouds: 34,
            visibility: 10000,
            wind_speed: 2.78,
            wind_deg: 258,
            wind_gust: 6.38,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634454000,
            temp: 6.91,
            feels_like: 4.34,
            pressure: 1022,
            humidity: 65,
            dew_point: 0.6,
            uvi: 0.5,
            clouds: 35,
            visibility: 10000,
            wind_speed: 3.76,
            wind_deg: 272,
            wind_gust: 6.05,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634457600,
            temp: 8.65,
            feels_like: 6.3,
            pressure: 1022,
            humidity: 56,
            dew_point: 0.38,
            uvi: 1.04,
            clouds: 36,
            visibility: 10000,
            wind_speed: 4.1,
            wind_deg: 284,
            wind_gust: 5.97,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634461200,
            temp: 9.71,
            feels_like: 7.47,
            pressure: 1021,
            humidity: 52,
            dew_point: 0.24,
            uvi: 1.54,
            clouds: 31,
            visibility: 10000,
            wind_speed: 4.4,
            wind_deg: 285,
            wind_gust: 5.56,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634464800,
            temp: 10.44,
            feels_like: 8.85,
            pressure: 1021,
            humidity: 50,
            dew_point: 0.3,
            uvi: 1.75,
            clouds: 35,
            visibility: 10000,
            wind_speed: 4.68,
            wind_deg: 279,
            wind_gust: 5.85,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634468400,
            temp: 11.03,
            feels_like: 9.39,
            pressure: 1020,
            humidity: 46,
            dew_point: -0.17,
            uvi: 1.67,
            clouds: 37,
            visibility: 10000,
            wind_speed: 4.97,
            wind_deg: 281,
            wind_gust: 6.23,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634472000,
            temp: 11.4,
            feels_like: 9.74,
            pressure: 1020,
            humidity: 44,
            dew_point: -0.56,
            uvi: 1.26,
            clouds: 43,
            visibility: 10000,
            wind_speed: 5.16,
            wind_deg: 284,
            wind_gust: 6.52,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03d'
              }
            ],
            pop: 0
          },
          {
            dt: 1634475600,
            temp: 11.29,
            feels_like: 9.62,
            pressure: 1020,
            humidity: 44,
            dew_point: -0.7,
            uvi: 0.78,
            clouds: 71,
            visibility: 10000,
            wind_speed: 5.05,
            wind_deg: 284,
            wind_gust: 6.46,
            weather: [
              {
                id: 803,
                main: 'Clouds',
                description: 'broken clouds',
                icon: '04d'
              }
            ],
            pop: 0
          }
        ],
        daily: [
          {
            dt: 1634292000,
            sunrise: 1634272649,
            sunset: 1634311422,
            moonrise: 1634306700,
            moonset: 1634248740,
            moon_phase: 0.33,
            temp: {
              day: 12.03,
              min: 2.88,
              max: 13.23,
              night: 6.34,
              eve: 12.21,
              morn: 2.92
            },
            feels_like: {
              day: 10.52,
              night: 3.42,
              eve: 10.56,
              morn: -0.75
            },
            pressure: 1020,
            humidity: 47,
            dew_point: 0.86,
            wind_speed: 6.35,
            wind_deg: 225,
            wind_gust: 11.4,
            weather: [
              {
                id: 804,
                main: 'Clouds',
                description: 'overcast clouds',
                icon: '04d'
              }
            ],
            clouds: 92,
            pop: 0,
            uvi: 1.32
          },
          {
            dt: 1634378400,
            sunrise: 1634359147,
            sunset: 1634397697,
            moonrise: 1634394360,
            moonset: 1634339820,
            moon_phase: 0.36,
            temp: {
              day: 12.68,
              min: 4.04,
              max: 13.67,
              night: 6.26,
              eve: 7.75,
              morn: 4.04
            },
            feels_like: {
              day: 11.52,
              night: 3.73,
              eve: 5.02,
              morn: 0.97
            },
            pressure: 1017,
            humidity: 58,
            dew_point: 4.48,
            wind_speed: 5.44,
            wind_deg: 291,
            wind_gust: 11.41,
            weather: [
              {
                id: 803,
                main: 'Clouds',
                description: 'broken clouds',
                icon: '04d'
              }
            ],
            clouds: 82,
            pop: 0,
            uvi: 1.56
          },
          {
            dt: 1634464800,
            sunrise: 1634445646,
            sunset: 1634483973,
            moonrise: 1634481780,
            moonset: 1634430900,
            moon_phase: 0.4,
            temp: {
              day: 10.44,
              min: 2.82,
              max: 11.4,
              night: 5.07,
              eve: 6.59,
              morn: 2.99
            },
            feels_like: {
              day: 8.85,
              night: 2.8,
              eve: 4.65,
              morn: -0.03
            },
            pressure: 1021,
            humidity: 50,
            dew_point: 0.3,
            wind_speed: 5.16,
            wind_deg: 284,
            wind_gust: 6.52,
            weather: [
              {
                id: 802,
                main: 'Clouds',
                description: 'scattered clouds',
                icon: '03d'
              }
            ],
            clouds: 35,
            pop: 0,
            uvi: 1.75
          },
          {
            dt: 1634547600,
            sunrise: 1634532145,
            sunset: 1634570251,
            moonrise: 1634569080,
            moonset: 1634521680,
            moon_phase: 0.43,
            temp: {
              day: 8.77,
              min: 3.68,
              max: 8.77,
              night: 7.2,
              eve: 7.77,
              morn: 5.08
            },
            feels_like: {
              day: 6.41,
              night: 3.58,
              eve: 4.6,
              morn: 2.68
            },
            pressure: 1021,
            humidity: 58,
            dew_point: 0.86,
            wind_speed: 6.31,
            wind_deg: 264,
            wind_gust: 12,
            weather: [
              {
                id: 804,
                main: 'Clouds',
                description: 'overcast clouds',
                icon: '04d'
              }
            ],
            clouds: 100,
            pop: 0.1,
            uvi: 1.32
          },
          {
            dt: 1634634000,
            sunrise: 1634618645,
            sunset: 1634656529,
            moonrise: 1634656320,
            moonset: 1634612460,
            moon_phase: 0.46,
            temp: {
              day: 7.2,
              min: 2.25,
              max: 9.16,
              night: 3.86,
              eve: 6.18,
              morn: 2.25
            },
            feels_like: {
              day: 4.61,
              night: 3.86,
              eve: 4.32,
              morn: -0.8
            },
            pressure: 1028,
            humidity: 60,
            dew_point: -0.15,
            wind_speed: 5.29,
            wind_deg: 313,
            wind_gust: 9.42,
            weather: [
              {
                id: 500,
                main: 'Rain',
                description: 'light rain',
                icon: '10d'
              }
            ],
            clouds: 90,
            pop: 1,
            rain: 3.63,
            uvi: 1.7
          },
          {
            dt: 1634720400,
            sunrise: 1634705145,
            sunset: 1634742808,
            moonrise: 1634743560,
            moonset: 1634703120,
            moon_phase: 0.5,
            temp: {
              day: 7.8,
              min: 3.5,
              max: 8.81,
              night: 8.81,
              eve: 8.79,
              morn: 4.99
            },
            feels_like: {
              day: 5.26,
              night: 6.59,
              eve: 6.4,
              morn: 1.99
            },
            pressure: 1018,
            humidity: 88,
            dew_point: 5.88,
            wind_speed: 4.25,
            wind_deg: 187,
            wind_gust: 9.25,
            weather: [
              {
                id: 500,
                main: 'Rain',
                description: 'light rain',
                icon: '10d'
              }
            ],
            clouds: 100,
            pop: 0.4,
            rain: 0.72,
            uvi: 2
          },
          {
            dt: 1634806800,
            sunrise: 1634791645,
            sunset: 1634829088,
            moonrise: 1634830920,
            moonset: 1634793720,
            moon_phase: 0.52,
            temp: {
              day: 14.98,
              min: 9.13,
              max: 15.68,
              night: 11.24,
              eve: 13.79,
              morn: 9.13
            },
            feels_like: {
              day: 13.79,
              night: 10.2,
              eve: 12.79,
              morn: 7.01
            },
            pressure: 1008,
            humidity: 48,
            dew_point: 3.89,
            wind_speed: 7.49,
            wind_deg: 227,
            wind_gust: 15.66,
            weather: [
              {
                id: 803,
                main: 'Clouds',
                description: 'broken clouds',
                icon: '04d'
              }
            ],
            clouds: 63,
            pop: 0,
            uvi: 2
          },
          {
            dt: 1634893200,
            sunrise: 1634878145,
            sunset: 1634915370,
            moonrise: 1634918400,
            moonset: 1634884320,
            moon_phase: 0.55,
            temp: {
              day: 15.15,
              min: 10.02,
              max: 16.52,
              night: 11.52,
              eve: 13.54,
              morn: 10.02
            },
            feels_like: {
              day: 14.24,
              night: 10.56,
              eve: 12.54,
              morn: 9.11
            },
            pressure: 1005,
            humidity: 58,
            dew_point: 6.81,
            wind_speed: 6.65,
            wind_deg: 242,
            wind_gust: 14.33,
            weather: [
              {
                id: 804,
                main: 'Clouds',
                description: 'overcast clouds',
                icon: '04d'
              }
            ],
            clouds: 100,
            pop: 0,
            uvi: 2
          }
        ]
      };

      return {
        status: 'ok',
        res
      };
      // throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  }
});
