import vuetifyEn from 'vuetify/es5/locale/en';
export default {
  $vuetify: { ...vuetifyEn },

  appName: 'lanet.house',

  auth: {
    title: 'Login',
    errorMsg: {
      failLoadIdentityInfo: 'Failed to get user info from server!',
      failLogout: 'Failed to logout!',
      axios: 'Axios error!'
    },
    label: {
      login: 'Login',
      logout: 'Password'
    },
    btn: {
      login: 'Login',
      logout: 'Logout'
    },
    message: {
      nameRequired: 'Name is required',
      nameLess: 'Name must be less than 16 characters',
      passRequired: 'Password is required',
      namePassWrong: "The login and password you entered don't match",
      accessGranted: 'Access granted'
    }
  },

  profile: {
    errorMsg: {
      failGetLog: "Failed to get log's data from server!",
      axios: 'Axios error!'
    },
    menu: {
      edit: 'Edit account',
      logout: 'Logout',
      chooseLang: 'Choose language',
      activityLog: 'Activity log'
    },
    theme: {
      light: 'Light theme',
      dark: 'Dark theme'
    },
    activityLogTable: {
      title: 'My activity',
      footerProps: {
        itemsPerPageText: 'Items per page',
        itemsPerPageAllText: 'All',
        pageText: 'of'
      },
      id: 'Id',
      timeStamp: 'Date time',
      operation: 'Operation',
      description: 'Description',
      actions: 'Actions',
      label: {
        filterDates: 'Filter by dates',
        filterOp: 'Filter by operations',
        search: 'Search in the description'
      },
      datePicker: {
        btn: {
          apply: 'Apply',
          cancel: 'Cancel'
        }
      }
    }
  },

  navMenu: {
    dashboard: 'Dashboard',
    controllers: 'Controllers',
    devices: 'Devices',
    groups: 'Groups',
    groupsMenu: {
      home: 'Home',
      corp: 'Corp'
    },
    home: 'Home',
    corp: 'Corp',
    users: 'Users'
  },

  dashboard: {
    title: 'Dashboard',
    subtitle: '',
    label: {},
    welcome: {
      title: 'Welcome to lanet.house!',
      subtitle:
        'Soon there will be a dashboard for convenient use of selected devices'
    },
    btn: {
      addGroup: 'Add dashboard'
    },
    message: {
      noGroupsFound: 'No dashboards found'
    }
  },

  controllers: {
    title: 'Controllers',
    subtitle: 'сontrollers and coordinators',
    label: {},
    btn: {
      addDevice: 'Add device'
    },
    message: {
      noDevicesFound: 'No devices found'
    }
  },

  devices: {
    title: 'Devices',
    subtitle: 'sensors and actuators',

    label: {},
    btn: {
      addDevice: 'Add device'
    },

    // errorMsg: {
    //   failAddDev: 'Failed to add a new device!',
    //   failDelDev: 'Failed to delete a device!',
    //   failUpdDev: 'Failed to update a device!',
    //   failStaDev: 'Failed to get a status of device!',
    //   failAddVirt: 'Failed to add a new virtual device!',
    //   failAddLine: 'Failed to add a line to a virtual device!',
    //   failDelLine: 'Failed to delele a line from a virtual device!',
    //   failGetValue: 'Failed to get the current value of a line!',
    //   failSetValue: 'Failed to set the current value of a line!',
    //   failLoadTypes: 'Failed to load a list of line types!',
    //   failGetSetting: 'Failed to load a device settings!',
    //   failSetSetting: 'Failed to save a device settings!'
    // },

    message: {
      noDevicesFound: 'No devices found'
    }
  },

  // orgs: {
  //   title: 'Select a group',
  //   subtitle: 'corporate and home',

  //   errorMsg: {
  //     failGetOrgs: "Failed to get group's data from server!",
  //     failAddOrg: 'Failed to add a new group!',
  //     failDelOrg: 'Failed to delete a group!',
  //     failUpdOrg: 'Failed to update a group!'
  //   },

  //   message: {
  //     noGroupsFound: 'No groups found',
  //     noOwner: 'Information not available'
  //   },

  //   menu: {
  //     edit: 'Settings',
  //     select: 'Select group'
  //   },

  //   btn: {
  //     newOrg: 'New group',
  //     cancel: 'Cancel',
  //     choose: 'Choose'
  //   },

  //   table: {
  //     title: 'My groups',
  //     lblSearch: 'Search',
  //     footerProps: {
  //       itemsPerPageText: 'Groups per page',
  //       itemsPerPageAllText: 'All',
  //       pageText: 'of'
  //     },
  //     id: 'Id',
  //     orgAvatar: 'Org avatar',
  //     owner: 'Owner',
  //     name: 'Name',
  //     type: 'Type',
  //     description: 'Description',
  //     actions: 'Actions'
  //   }
  // },

  // editOrg: {
  //   title: 'Settings',
  //   subtitle: 'of corporate or home',

  //   tooltip: {
  //     refreshForm: 'Refresh form'
  //   },

  //   rulesMsg: {
  //     avatarSize: 'Avatar size should be less than 2 MB!',
  //     required: 'This field is required',
  //     nameLen: 'Name must be between 3 and 25 characters!',
  //     descLen: 'Description must be less than 255 characters'
  //   },

  //   btn: {
  //     save: 'Save',
  //     delete: 'Delete',
  //     cancel: 'Cancel'
  //   },

  //   form: {
  //     lblOrgId: 'Group ID',
  //     phOrgId: 'Id',
  //     lblOrgAvatar: 'Avatar',
  //     phOrgAvatar: 'Pick an avatar',
  //     lblOrgType: 'Group type',
  //     phOrgType: 'Select...',
  //     lblOrgName: 'Group Name',
  //     phOrgName: `LTD 'Lanet Network'`,
  //     lblOrgDescription: 'Group description',
  //     phOrgDescription: 'ISP Provider',

  //     orgTypes: {
  //       home: 'Home',
  //       business: 'Business'
  //     }
  //   }
  // },

  // location: {
  //   title: 'Select an location',
  //   subtitle: '',

  //   errorMsg: {
  //     failGetLoc: "Failed to get location's data from server!",
  //     failAddLoc: 'Failed to add a new location!',
  //     failDelLoc: 'Failed to delete a location!',
  //     failUpdLoc: 'Failed to update a location!'
  //   },

  //   message: {
  //     noLocationsFound: 'No locations found'
  //   },

  //   lblSearch: 'Search',

  //   menu: {
  //     edit: 'Settings',
  //     select: 'Select...'
  //   },

  //   btn: {
  //     newLoc: 'New location',
  //     cancel: 'Cancel',
  //     choose: 'Choose'
  //   }
  // },

  group: {
    title: 'Groups',
    btn: {
      add: 'Add group'
    },
    menu: {
      select: 'Select group',
      change: 'Edit group',
      remove: 'Remove group'
    },
    add: {
      header: 'Add new group',
      bodyHead: 'A new group will be created',
      bodyTail:
        '. Select a group type and enter a name and description for the group.',
      labelId: 'Group ID',
      labelType: 'Group type',
      labelName: 'Group name',
      labelDesc: 'Group description',
      labelIcon: 'Group avatar',
      accept: 'Add'
    },
    edit: {
      header: 'Edit group',
      bodyHead: 'Enter a new name for the group',
      bodyTail: '. You can also change the group description.',
      labelId: 'Group ID',
      labelType: 'Group type',
      labelName: 'Group name',
      labelDesc: 'Group description',
      labelIcon: 'Group avatar',
      accept: 'Update'
    },
    del: {
      header: 'Remove group',
      bodyHead: 'Group',
      bodyTail: 'will be deleted.',
      notEmpty:
        'You should remove all child locations, devices, and rules first',
      accept: 'Delete'
    },
    errorMsg: {
      failGetOrgs: "Failed to get group's data from server!",
      failAddOrg: 'Failed to add a new group!',
      failDelOrg: 'Failed to delete a group!',
      failUpdOrg: 'Failed to update a group!'
    }
  },

  acl: {
    menu: {
      remove: 'Remove rule'
    },
    errorMsg: {
      failAddACL: 'Failed to add a new access rule!',
      failDelACL: 'Failed to delete an access rule!',
      failLstACL: 'Failed to get a list of access rules!',
      notOwner: 'You cannot choose the owner',
      double: 'An access rule for this user already exists on this node'
    },
    del: {
      header: 'Remove access rule',
      bodyHead: 'Access rule',
      bodyChest: 'to node',
      bodyWaist: 'for',
      bodyTail: 'will be deleted. This action is irreversible.',
      accept: 'Delete'
    }
  },

  node: {
    title: 'Location',
    btn: {
      addNode: 'Location',
      addDev: 'Device',
      addRule: 'Rule'
    },
    menu: {
      addNode: 'Add location',
      editNode: 'Rename location',
      shareNode: 'Share location',
      moveNode: 'Move location',
      delNode: 'Remove location',
      addDev: 'Add device',
      addRule: 'Add rule'
    },
    tip: {
      isRoot: 'You can not remove root node',
      notEmpty: 'You should remove children nodes first'
    },
    tab: {
      info: 'Info',
      child: 'Children',
      access: 'Access'
    },
    confirm: {
      sure: 'Are you sure?'
    },
    add: {
      header: 'Add new location',
      bodyHead: 'A new child location will be added to the location',
      bodyTail: '.',
      label: 'Location name',
      accept: 'Add'
    },
    edit: {
      header: 'Rename location',
      bodyHead: 'Enter a new name for the location',
      bodyTail: '.',
      label: 'Location name',
      accept: 'Update'
    },
    move: {
      header: 'Move location',
      bodyHead: 'Location ',
      bodyTail: 'will be moved. Select the new place.',
      label: 'New location',
      accept: 'Move'
    },
    share: {
      header: 'Share location',
      bodyHead:
        'Enter the name or email of the user with whom you want to share the location',
      bodyTail: '. Also specify the access level for this user.',
      label: 'User name or email',
      noData: 'Search for a user with whom you want to share the location',
      accept: 'Share'
    },
    del: {
      header: 'Remove location',
      bodyHead: 'Location',
      bodyTail: 'will be deleted.',
      accept: 'Delete'
    },
    is: {
      shared: 'This node is shared'
    },
    errorMsg: {
      failGetLoc: 'Failed to get location data from server!',
      failAddLoc: 'Failed to add a new location!',
      failUpdLoc: 'Failed to update a location!',
      failDelLoc: 'Failed to delete a location!'
    }
  },

  device: {
    title: 'Device',
    btn: {
      refresh: 'Refresh',
      edit: 'Edit',
      remove: 'Remove',
      table: 'Table',
      chart: 'Chart',
      hideOpt: 'Hide options',
      showOpt: 'Show options',
      repeatOn: 'Repeat',
      repeatOff: 'Break'
    },
    menu: {
      edit: 'Rename device',
      share: 'Share device',
      move: 'Move device',
      remove: 'Remove device'
    },
    tab: {
      info: 'Info',
      lines: 'Lines',
      config: 'Config',
      status: 'Status',
      errors: 'Errors',
      access: 'Access',
      setup: 'Settings'
    },
    confirm: {
      sure: 'Are you sure?'
    },
    add: {
      header: 'Add new device',
      bodyHead: 'A new device will be added to the location',
      bodyTail:
        '. Enter a device name and select its type. Also, if necessary, enter a hardware identifier (HWID).',
      labelId: 'Device HWID',
      labelName: 'Device name',
      labelBroker: 'MQTT broker',
      accept: 'Add'
    },
    edit: {
      header: 'Rename device',
      bodyHead: 'Enter a new name for the device',
      bodyTail: '.',
      labelId: 'Device HWID',
      labelName: 'Device name',
      accept: 'Update'
    },
    move: {
      header: 'Move device',
      bodyHead: 'Device ',
      bodyTail: 'will be moved. Select the new place.',
      label: 'New location',
      accept: 'Move'
    },
    share: {
      header: 'Share device',
      bodyHead:
        'Enter the name or email of the user with whom you want to share the device',
      bodyTail: '. Also specify the access level for this user.',
      label: 'User name or email',
      noData: 'Search for a user with whom you want to share the device',
      accept: 'Share'
    },
    del: {
      header: 'Remove device',
      bodyHead: 'Device',
      bodyTail: 'will be deleted.',
      widgets: 'In addition, the following widgets will be removed:',
      accept: 'Delete'
    },
    qr: {
      header: 'Scan QR-code',
      init: 'Please grant access to the camera. ',
      scan: 'Aim the camera at the QR-code and wait for the recognition to complete.',
      error: 'An error has occurred.',
      errors: {
        NotAllowedError: 'User denied camera access permisson.',
        NotFoundError: 'No suitable camera device installed.',
        NotSupportedError: 'The page is not served over HTTPS (or localhost)',
        NotReadableError: 'Maybe camera is already in use',
        OverconstrainedError:
          'Did you requested the front camera although there is none?',
        StreamApiNotSupportedError: 'Browser seems to be lacking features'
      }
    },
    reset: {
      button: 'Reset',
      header: 'Reset device settings',
      bodyHead: 'The settings for device',
      bodyTail: 'will be reseted to default.',
      accept: 'Reset'
    },
    is: {
      shared: 'This device is shared'
    },
    state: {
      VIRTUAL: 'Virtual Device',
      ONLINE: 'Online Device',
      OFFLINE: 'Offline Device',
      UNKNOWN: 'Unregistered Device',
      SHARED: 'Shared nodes',
      NODE: 'Locations only'
    },
    errorMsg: {
      failAddDev: 'Failed to add a new device!',
      failDelDev: 'Failed to delete a device!',
      failUpdDev: 'Failed to update a device!',
      failStaDev: 'Failed to get a status of device!',
      failAddVirt: 'Failed to add a new virtual device!',
      failAddLine: 'Failed to add a line to a virtual device!',
      failDelLine: 'Failed to delele a line from a virtual device!',
      failGetValue: 'Failed to get the current value of a line!',
      failSetValue: 'Failed to set the current value of a line!',
      failLoadTypes: 'Failed to load a list of line types!',
      failGetBrokers: 'Failed to get a list of MQTT brokers!',
      failGetSetting: 'Failed to load a device settings!',
      failSetSetting: 'Failed to save a device settings!'
    }
  },

  line: {
    table: {
      add: 'Add line',
      history: 'History',
      change: 'Change',
      widget: 'Widget',
      rename: 'Rename',
      delete: 'Remove'
    },
    add: {
      header: 'Add new line',
      bodyHead: 'A new line will be added to the device',
      bodyTail: '. Enter a name, description, and type.',
      labelName: 'Line name',
      labelDesc: 'Description',
      labelType: 'Line type',
      accept: 'Add'
    },
    edit: {
      header: 'Rename line',
      bodyHead: 'Enter a new name for the line',
      bodyTail: '.',
      labelName: 'Line name',
      labelDesc: 'Description',
      accept: 'Update'
    },
    value: {
      header: 'Change value',
      bodyHead: 'Enter a new value for the line',
      bodyTail: '.',
      label: 'Value',
      accept: 'Change'
    },
    del: {
      header: 'Remove line',
      bodyHead: 'Line',
      bodyTail: ' will be deleted.',
      widgets: 'In addition, the following widgets will be removed:',
      accept: 'Delete'
    }
  },

  widget: {
    menu: {
      add: 'Add widget',
      info: 'Widget info',
      rename: 'Rename widget',
      remove: 'Remove widget',
      bundle: 'Add bundle',
      arrange: 'Arrange widgets'
    },
    info: {
      header: 'Widget info',
      goTo: 'Go To Device'
    },
    add: {
      header: 'Add new widget',
      bodyHead: 'A new widget based on the line ',
      bodyTail:
        ' will be added to the dashboard. Enter a widget name and select its type.',
      labelName: 'Widget name',
      labelType: 'Widget type',
      accept: 'Add'
    },
    create: {
      header: 'Create new widget',
      body: 'To create a new widget, you need to follow a few steps. The first step is to select a location. The second step is to select a device. The third step is to select a line. At the last step, enter a name for the widget and select its type.',
      labelName: 'Widget name',
      labelType: 'Widget type',
      accept: 'Create'
    },
    edit: {
      header: 'Rename widget',
      bodyHead: 'Enter a new name for the widget',
      bodyTail: '.',
      labelName: 'Widget name',
      accept: 'Update'
    },
    del: {
      header: 'Remove widget',
      bodyHead: 'Widget',
      bodyTail: ' will be deleted.',
      accept: 'Delete'
    },
    list: {
      ShowChart: 'Line chart',
      ShowValue: 'Current value',
      EditValue: 'Change value'
    }
  },

  rule: {
    menu: {},
    errorMsg: {
      failAddRule: 'Failed to add a new rule!',
      failDelRule: 'Failed to delete a rule!',
      failInfRule: 'Failed to get a rule`s info!',
      failLinesIn: 'Failed to get a list of input lines for the rule!',
      failLinesOut: 'Failed to get a list of output lines for the rule!',
      failLoadTypes: 'Failed to load a list of rule types!'
    }
  },

  iface: {
    btn: {
      continue: 'Continue',
      reject: 'Cancel',
      gotIt: 'Got it',
      clear: 'Clear',
      save: 'Save',
      back: 'Back',
      more: 'More',
      ok: 'Ok'
    },
    tip: {
      owner: 'You should be owner to share',
      inDev: 'Temporarily disabled. Feature is in development.'
    },
    confirm: {
      irreversible: 'This action is irreversible!',
      sure: 'Are you sure?'
    },
    search: {
      user: 'Search for a user...',
      hint: 'Type at least 3 symbols',
      label: 'Search'
    },
    filter: {
      title: 'Filter',
      nodes: 'Locations only',
      shared: 'Shared nodes'
    },
    label: {
      date: 'Date',
      time: 'Time',
      confirm: 'Confirm'
    },
    level: {
      owner: 'Owner',
      admin: 'Admin',
      user: 'User',
      none: 'None'
    },
    origin: {
      real: 'Real',
      virt: 'Virtual'
    },
    type: {
      toggle_switch: 'Toggle switch',
      rgb_color: 'RGB color'
    },
    orgType: {
      home: 'Home',
      firm: 'Business'
    },
    prop: {
      org_id: 'Group ID',
      id: 'ID',
      hwid: 'HWID',
      name: 'Name',
      node: 'Node',
      line: 'Line',
      type: 'Type',
      group: 'Group',
      owner: 'Owner',
      device: 'Device',
      widget: 'Widget',
      parent: 'Parent',
      source: 'Source',
      target: 'Target',
      location: 'Location',
      access_level: 'Access level',

      topic: 'Topic',
      value: 'Value',
      unit: 'Unit',
      date: 'Date',
      time: 'Time',
      level: 'Level',
      user: 'User',
      rule: 'Rule',
      error: 'Error',
      actions: 'Act',
      group_pack: 'Owner, Group',

      title: 'Title',
      component: 'Component',
      desc: 'Description',

      device_name: 'Device name',
      model_name: 'Model name',
      hardware: 'Hardware ver.',
      software: 'Software ver.',
      release: 'Release',
      build: 'Build',
      manufacturer: 'Manufacturer',
      power: 'Power',
      can_listen: 'Can listen',

      temperature: 'Temperature',
      connection: 'Connection',
      rssi: 'RSSI',
      quality: 'Quality',
      state: 'State',

      keep_alive: 'Keep alive',
      time_publish: 'Time publish',
      battery_type: 'Battery type',
      charging_current: 'Charging current'
    },
    err: {
      error: 'Error',
      goHome: 'Home page',
      require: 'This field is required',
      space: 'No spaces are allowed',
      empty: 'This field must be not empty',
      alphanum: 'Only Latin letters and numbers are allowed',
      decimal: 'Only decimal characters are allowed',
      hexadec: 'Only hexadecimal characters are allowed',
      minHead: 'A minimum of',
      minTail: 'characters is allowed',
      maxHead: 'A maximum of',
      maxTail: 'characters is allowed',
      minimum: 'Minimum value:',
      maximum: 'Maximum value:',
      nothing: 'There is nothing here',
      earlier: 'earlier than start',
      itself: 'Cannot transfer a node to itself',
      areSame: 'Source and destination are the same',
      400: 'Bad Request. The request cannot be executed.',
      401: 'Unauthorized access. Authentication is required.',
      403: 'Forbidden. The server is refusing request.',
      404: 'This page could not be found',
      405: 'A request method is not supported for the requested resource',
      500: 'Internal Server Error',
      501: 'Not Implemented',
      502: 'Bad Gateway',
      503: 'Service Unavailable'
    }
  },

  err: {
    ACLexists: {
      title: 'Access rule exists',
      detail: 'An access rule for this user already exists on this node'
    },
    Registered: {
      title: 'Already registered',
      detail: 'This device is already registered'
    }
  }
};
