import { getErrorResponse } from '~/utils/apiUtils';

// https://kp-staging.lanet.house/public/#api-Me
export default ($axios) => ({
  // https://kp-staging.lanet.house/public/#api-Me-PostMeInventory
  // Add inventory for user
  async inventoryAdd(key, data) {
    try {
      const { status } = await $axios.$post('/v1/me/inventory', { key, data });
      if (status === 'ok') {
        return {
          status
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Me-DelMeInventoryKey
  // Delete user's inventory by key
  async inventoryDel(key) {
    try {
      const { status } = await $axios.$delete(`/v1/me/inventory/${key}`);
      if (status === 'ok') {
        return {
          status
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Me-GetMeInventoryByKey
  // Get user's inventory by key
  async inventoryGet(key) {
    try {
      const { data } = await $axios.$get(`/v1/me/inventory/${key}`);
      return {
        status: 'ok',
        key,
        data
      };
      // throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Me-GetMeInventory
  // Get user's inventory list
  async inventoryList() {
    try {
      const { keys } = await $axios.$get('/v1/me/inventory');
      return {
        status: 'ok',
        keys
      };
      // throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Me-PutMeInventory
  // Update user's inventory by key
  async inventoryUpdate(key, data) {
    try {
      const { status } = await $axios.$patch(`/v1/me/inventory/${key}`, {
        data
      });
      if (status === 'ok') {
        return {
          status
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Me-GetMeJournal
  // Get information about user actions
  async journal(params) {
    try {
      // eslint-disable-next-line camelcase
      const { total, items, filter_values } = await $axios.$get(
        '/v1/me/journal',
        { params }
      );
      if (total >= 0) {
        return {
          status: 'ok',
          total,
          items,
          filter_values
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Me-GetMe
  // Get information about user
  async me() {
    try {
      const { status, user } = await $axios.$get('/v1/me');
      if (status === 'ok') {
        return {
          status,
          data: user || {}
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  }
});
