
export default {
  name: 'Edit',

  props: {
    enable: Boolean,
    text: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      width: '640px',
      nodeName: ''
    };
  },

  methods: {
    emit(e) {
      if (e) this.$emit(e);
    }
  }
};
