import vuetifyPl from 'vuetify/es5/locale/pl';
export default {
  $vuetify: { ...vuetifyPl },

  appName: 'lanet.house',

  auth: {
    title: 'Login',
    errorMsg: {
      failLoadIdentityInfo:
        'Nie udało się pobrać informacji o użytkowniku z serwera!',
      failLogout: 'Nie udało się wylogować!',
      axios: 'Błąd Axios!'
    },
    label: {
      login: 'Login',
      logout: 'Hasło'
    },
    btn: {
      login: 'Logowanie',
      logout: 'Wylogowanie'
    },
    message: {
      nameRequired: 'Nazwa jest wymagana',
      nameLess: 'Nazwa musi mieć mniej niż 16 znaków',
      passRequired: 'Hasło jest wymagane',
      namePassWrong: 'Wprowadzony login i hasło nie są zgodne.',
      accessGranted: 'Dostęp przyznany'
    }
  },

  profile: {
    errorMsg: {
      failGetLog: 'Nie udało się pobrać danych dziennika z serwera!',
      axios: 'Błąd Axios!'
    },
    menu: {
      edit: 'Edytuj konto',
      logout: 'Wylogowanie',
      chooseLang: 'Wybierz język',
      activityLog: 'Dziennik działań'
    },
    theme: {
      light: 'Motyw jasny',
      dark: 'Motyw ciemny'
    },
    activityLogTable: {
      title: 'Moja działalność',
      footerProps: {
        itemsPerPageText: 'Pozycje na stronie',
        itemsPerPageAllText: 'Wszystkie',
        pageText: 'z'
      },
      id: 'Id',
      timeStamp: 'Data i czas',
      operation: 'Operacja',
      description: 'Opis',
      actions: 'Działania',
      label: {
        filterDates: 'Filtruj według dat',
        filterOp: 'Filtruj według operacji',
        search: 'Szukaj w opisie'
      },
      datePicker: {
        btn: {
          apply: 'Zastosuj',
          cancel: 'Anuluj'
        }
      }
    }
  },

  navMenu: {
    dashboard: 'Pulpit',
    controllers: 'Kontrolery',
    devices: 'Urządzenia',
    groups: 'Grupy',
    groupsMenu: {
      home: 'Dom',
      corp: 'Korp'
    },
    home: 'Dom',
    corp: 'Korp',
    users: 'Użytkownicy'
  },

  dashboard: {
    title: 'Pulpit',
    subtitle: '',
    label: {},
    welcome: {
      title: 'Witamy na lanet.house!',
      subtitle:
        'Wkrótce pojawi się tutaj pulpit nawigacyjny do wygodnego korzystania z wybranych urządzeń'
    },
    btn: {
      addGroup: 'Dodać pulpit'
    },
    message: {
      noGroupsFound: 'Nie znaleziono żadnych pulpitów'
    }
  },

  controllers: {
    title: 'Kontrolery',
    subtitle: 'kontrolerzy i koordynatorzy',
    label: {},
    btn: {
      addDevice: 'Dodać urządzenie'
    },
    message: {
      noDevicesFound: 'Nie znaleziono żadnych urządzeń'
    }
  },

  devices: {
    title: 'Urządzenia',
    subtitle: 'czujniki i siłowniki',

    label: {},
    btn: {
      addDevice: 'Dodać urządzenie'
    },

    // errorMsg: {
    //   failAddDev: 'Nie udało się dodać nowego urządzenia!',
    //   failDelDev: 'Nie udało się usunąć urządzenia!',
    //   failUpdDev: 'Nie udało się zaktualizować urządzenia!',
    //   failStaDev: 'Nie udało się uzyskać stanu urządzenia!',
    //   failAddVirt: 'Nie udało się dodać nowego urządzenia wirtualnego!',
    //   failAddLine: 'Nie udało się dodać linii do urządzenia wirtualnego!',
    //   failDelLine: 'Nie udało się usunąć linii z urządzenia wirtualnego!',
    //   failGetValue: 'Nie udało się uzyskać aktualnego waloru linii!',
    //   failSetValue: 'Nie udało się ustawić aktualnego waloru linii!',
    //   failLoadTypes: 'Nie udało się załadować listy rodzajów linii!',
    //   failGetSetting: 'Nie udało się załadować ustawień urządzenia!',
    //   failSetSetting: 'Nie udało się zapisać ustawień urządzenia!'
    // },

    message: {
      noDevicesFound: 'Nie znaleziono żadnych urządzeń'
    }
  },

  // orgs: {
  //   title: 'Wybierz grupę',
  //   subtitle: 'korporacyjne i domowe',

  //   errorMsg: {
  //     failGetOrgs: 'Nie udało się pobrać danych grupy z serwera!',
  //     failAddOrg: 'Nie udało się dodać nowej grupy!',
  //     failDelOrg: 'Nie udało się usunąć grupy!',
  //     failUpdOrg: 'Nie udało się zaktualizować grupy!'
  //   },

  //   message: {
  //     noGroupsFound: 'Nie znaleziono żadnych grup',
  //     noOwner: 'Informacja nie jest dostępna'
  //   },

  //   menu: {
  //     edit: 'Ustawienia',
  //     select: 'Wybierz grupę'
  //   },

  //   btn: {
  //     newOrg: 'Nowa grupa',
  //     cancel: 'Anuluj',
  //     choose: 'Wybierz'
  //   },

  //   table: {
  //     title: 'Moje grupy',
  //     lblSearch: 'Szukaj',
  //     footerProps: {
  //       itemsPerPageText: 'Grupy na stronie',
  //       itemsPerPageAllText: 'Wszystkie',
  //       pageText: 'z'
  //     },
  //     id: 'Id',
  //     orgAvatar: 'Awatar grupy',
  //     owner: 'Właściciel',
  //     name: 'Nazwa',
  //     type: 'Rodzaj',
  //     description: 'Opis',
  //     actions: 'Działania'
  //   }
  // },

  // editOrg: {
  //   title: 'Ustawienia',
  //   subtitle: 'korporacyjnej lub domowej',

  //   tooltip: {
  //     refreshForm: 'Odśwież formularz'
  //   },

  //   rulesMsg: {
  //     avatarSize: 'Rozmiar awatara powinien być mniejszy niż 2 MB!',
  //     required: 'To pole jest wymagane',
  //     nameLen: 'Nazwa musi zawierać od 3 do 25 znaków!',
  //     descLen: 'Opis musi być krótszy niż 255 znaków!'
  //   },

  //   btn: {
  //     save: 'Zapisz',
  //     delete: 'Usuń',
  //     cancel: 'Anuluj'
  //   },

  //   form: {
  //     lblOrgId: 'ID grupy',
  //     phOrgId: 'Id',
  //     lblOrgAvatar: 'Awatar',
  //     phOrgAvatar: 'Wybierz awatara',
  //     lblOrgType: 'Rodzaj grupy',
  //     phOrgType: 'Wybierz...',
  //     lblOrgName: 'Nazwa grupy',
  //     phOrgName: `LTD 'Lanet Network'`,
  //     lblOrgDescription: 'Opis grupy',
  //     phOrgDescription: 'ISP Provider',

  //     orgTypes: {
  //       home: 'Dom',
  //       business: 'Firma'
  //     }
  //   }
  // },

  // location: {
  //   title: 'Wybierz lokację',
  //   subtitle: '',

  //   errorMsg: {
  //     failGetLoc: 'Nie udało się pobrać danych o lokacji z serwera!',
  //     failAddLoc: 'Nie udało się dodać nowej lokacji!',
  //     failDelLoc: 'Nie udało się usunąć lokacji!',
  //     failUpdLoc: 'Nie udało się zaktualizować lokacji!'
  //   },

  //   message: {
  //     noLocationsFound: 'Nie znaleziono żadnych lokacji'
  //   },

  //   lblSearch: 'Szukaj',

  //   menu: {
  //     edit: 'Ustawienia',
  //     select: 'Wybierz...'
  //   },

  //   btn: {
  //     newLoc: 'Nowa lokacja',
  //     cancel: 'Anuluj',
  //     choose: 'Wybierz'
  //   }
  // },

  group: {
    title: 'Grupy',
    btn: {
      add: 'Dodać grupę'
    },
    menu: {
      select: 'Wybrać grupę',
      change: 'Edytować grupę',
      remove: 'Usunąć grupę'
    },
    add: {
      header: 'Dodanie nowej grupy',
      bodyHead: 'Zostanie utworzona nowa grupa',
      bodyTail: '. Wybierz rodzaj grupy i wprowadź jej nazwę oraz opis.',
      labelId: 'ID grupy',
      labelType: 'Rodzaj grupy',
      labelName: 'Nazwa grupy',
      labelDesc: 'Opis grupy',
      labelIcon: 'Awatar grupy',
      accept: 'Dodać'
    },
    edit: {
      header: 'Edycja grupy',
      bodyHead: 'Wprowadź nową nazwę dla grupy',
      bodyTail: '. Można również zmienić opis grupy.',
      labelId: 'ID grupy',
      labelType: 'Rodzaj grupy',
      labelName: 'Nazwa grupy',
      labelDesc: 'Opis grupy',
      labelIcon: 'Awatar grupy',
      accept: 'Aktualizować'
    },
    del: {
      header: 'Usunięcie grupy',
      bodyHead: 'Grupa',
      bodyTail: 'zostanie usunięta.',
      notEmpty: 'Najpierw należy usunąć podrzędne lokacje, urządzenia i reguły',
      accept: 'Usunąć'
    },
    errorMsg: {
      failGetOrgs: 'Nie udało się pobrać danych grupy z serwera!',
      failAddOrg: 'Nie udało się dodać nowej grupy!',
      failDelOrg: 'Nie udało się usunąć grupy!',
      failUpdOrg: 'Nie udało się zaktualizować grupy!'
    }
  },

  acl: {
    menu: {
      remove: 'Usunąć regułę'
    },
    errorMsg: {
      failAddACL: 'Nie udało się dodać nowej reguły dostępu!',
      failDelACL: 'Nie udało się usunąć reguły dostępu!',
      failLstACL: 'Nie udało się uzyskać listy reguł dostępu!',
      notOwner: 'Nie można wybrać właściciela',
      double: 'Reguła dostępu dla tego użytkownika już istnieje w tym węźle.'
    },
    del: {
      header: 'Usuwanie reguły dostępu',
      bodyHead: 'Reguła dostępu ',
      bodyChest: 'do węzła',
      bodyWaist: 'dla',
      bodyTail: 'zostanie usunięta. Jest to działanie nieodwracalne.',
      accept: 'Usuń'
    }
  },

  node: {
    title: 'Lokacja',
    btn: {
      addNode: 'Lokacja',
      addDev: 'Urządzenie',
      addRule: 'Reguła'
    },
    menu: {
      addNode: 'Dodać lokację',
      editNode: 'Nazwać lokację',
      shareNode: 'Udostępnić lokację',
      moveNode: 'Przenieść lokację',
      delNode: 'Usunąć lokację',
      addDev: 'Dodać urządzenie',
      addRule: 'Dodać regułę'
    },
    tip: {
      isRoot: 'Nie można usunąć węzła głównego',
      notEmpty: 'Najpierw należy usunąć węzły zagnieżdżone'
    },
    tab: {
      info: 'Info',
      child: 'Dzieci',
      access: 'Dostęp'
    },
    confirm: {
      sure: 'Na pewno?'
    },
    add: {
      header: 'Dodanie nowej lokacji',
      bodyHead: 'Nowa zagnieżdżona lokacja zostanie dodana do lokacji',
      bodyTail: '.',
      label: 'Nazwa lokacji',
      accept: 'Dodać'
    },
    edit: {
      header: 'Zmiana nazwy lokacji',
      bodyHead: 'Wprowadź nową nazwę dla lokacji',
      bodyTail: '.',
      label: 'Nazwa lokacji',
      accept: 'Nazwać'
    },
    move: {
      header: 'Przeniesienie lokacji',
      bodyHead: 'Lokacja ',
      bodyTail: ' zostanie przeniesiona. Wybierz nowe miejsce.',
      label: 'Nowe miejsce',
      accept: 'Przenieść'
    },
    share: {
      header: 'Udostępnianie lokacji',
      bodyHead:
        'Wprowadź imię i nazwisko lub adres e-mail użytkownika, któremu chcesz udostępnić lokalizację',
      bodyTail: '. Określ również poziom dostępu dla tego użytkownika.',
      label: 'Imię i nazwisko użytkownika lub e-mail',
      noData: 'Wyszukaj użytkownika, z którym chcesz udostępnić lokację',
      accept: 'Udostępnić'
    },
    del: {
      header: 'Usunięcie lokacji',
      bodyHead: 'Lokacja',
      bodyTail: 'zostanie usunięta.',
      accept: 'Usunąć'
    },
    is: {
      shared: 'Ta lokacja jest udostępniana'
    },
    errorMsg: {
      failGetLoc: 'Nie udało się pobrać danych o lokacji z serwera!',
      failAddLoc: 'Nie udało się dodać nowej lokacji!',
      failUpdLoc: 'Nie udało się zaktualizować lokacji!',
      failDelLoc: 'Nie udało się usunąć lokacji!'
    }
  },

  device: {
    title: 'Urządzenie',
    btn: {
      refresh: 'Odświeżyć',
      edit: 'Nazwać',
      remove: 'Usunąć',
      table: 'Tabela',
      chart: 'Wykres',
      hideOpt: 'Ukryć opcje',
      showOpt: 'Pokazać opcje',
      repeatOn: 'Powtarzać',
      repeatOff: 'Przerwać'
    },
    menu: {
      edit: 'Nazwać urządzenie',
      share: 'Udostępnić urządzenie',
      move: 'Przenieść urządzenie',
      remove: 'Usunąć urządzenie'
    },
    tab: {
      info: 'Info',
      lines: 'Linie',
      config: 'Konfig',
      status: 'Status',
      errors: 'Błędy',
      access: 'Dostęp',
      setup: 'Ustawienia'
    },
    confirm: {
      sure: 'Na pewno?'
    },
    add: {
      header: 'Dodanie nowego urządzenia',
      bodyHead: 'Nowe urządzenie zostanie dodane do lokacji',
      bodyTail:
        '. Wprowadź nazwę urządzenia i wybierz jego rodzaj. W razie potrzeby wprowadź także identyfikator urządzenia (HWID).',
      labelId: 'HWID urządzenia',
      labelName: 'Nazwa urządzenia',
      labelBroker: 'MQTT broker',
      accept: 'Dodać'
    },
    edit: {
      header: 'Zmiana nazwy urządzenia',
      bodyHead: 'Wprowadź nową nazwę dla urządzenia',
      bodyTail: '.',
      labelId: 'HWID urządzenia',
      labelName: 'Nazwa urządzenia',
      accept: 'Nazwać'
    },
    move: {
      header: 'Przeniesienie urządzenia',
      bodyHead: 'Urządzenie',
      bodyTail: 'zostanie przeniesione. Wybierz nowe miejsce.',
      label: 'Nowe miejsce',
      accept: 'Przenieść'
    },
    share: {
      header: 'Udostępnianie urządzenia',
      bodyHead:
        'Wprowadź imię i nazwisko lub adres e-mail użytkownika, któremu chcesz udostępnić urządzenie',
      bodyTail: '. Określ również poziom dostępu dla tego użytkownika.',
      label: 'Imię i nazwisko użytkownika lub e-mail',
      noData: 'Wyszukaj użytkownika, z którym chcesz udostępnić urządzenie',
      accept: 'Udostępnić'
    },
    del: {
      header: 'Usunięcie urządzenia',
      bodyHead: 'Urządzenie',
      bodyTail: 'zostanie usunięte.',
      widgets: 'Ponadto usunięte zostaną następujące widżety:',
      accept: 'Usunąć'
    },
    qr: {
      header: 'Skanowanie kodu QR',
      init: 'Proszę o zapewnienie dostępu do kamery.',
      scan: 'Skieruj aparat na kod QR i poczekaj na zakończenie rozpoznawania.',
      error: 'Wystąpił błąd.',
      errors: {
        NotAllowedError: 'Użytkownik odmówił dostępu do kamery.',
        NotFoundError:
          'Brak zainstalowanego odpowiedniego urządzenia kamerowego.',
        NotSupportedError:
          'Strona nie jest obsługiwana przez HTTPS (lub localhost)',
        NotReadableError: 'Może kamera jest już w użyciu',
        OverconstrainedError:
          'Czy wnioskowałeś o przednią kamerę, mimo że jej nie ma?',
        StreamApiNotSupportedError:
          'Wydaje się, że w przeglądarce brakuje funkcji'
      }
    },
    reset: {
      button: 'Zresetować',
      header: 'Resetowanie ustawień urządzenia',
      bodyHead: 'Ustawienia dla urządzenia',
      bodyTail: 'zostaną przywrócone do wartości domyślnych.',
      accept: 'Zresetować'
    },
    is: {
      shared: 'To urządzenie jest udostępniane'
    },
    state: {
      VIRTUAL: 'Urządzenie wirtualne',
      ONLINE: 'Urządzenie online',
      OFFLINE: 'Urządzenie offline',
      UNKNOWN: 'Urządzenie niezarejestrowane',
      SHARED: 'Współdzielone',
      NODE: 'Tylko lokacje'
    },
    errorMsg: {
      failAddDev: 'Nie udało się dodać nowego urządzenia!',
      failDelDev: 'Nie udało się usunąć urządzenia!',
      failUpdDev: 'Nie udało się zaktualizować urządzenia!',
      failStaDev: 'Nie udało się uzyskać stanu urządzenia!',
      failAddVirt: 'Nie udało się dodać nowego urządzenia wirtualnego!',
      failAddLine: 'Nie udało się dodać linii do urządzenia wirtualnego!',
      failDelLine: 'Nie udało się usunąć linii z urządzenia wirtualnego!',
      failGetValue: 'Nie udało się uzyskać aktualnego waloru linii!',
      failSetValue: 'Nie udało się ustawić aktualnego waloru linii!',
      failLoadTypes: 'Nie udało się uzyskać listy rodzajów linii!',
      failGetBrokers: 'Nie udało się uzyskać listy brokerów MQTT!',
      failGetSetting: 'Nie udało się załadować ustawień urządzenia!',
      failSetSetting: 'Nie udało się zapisać ustawień urządzenia!'
    }
  },

  line: {
    table: {
      add: 'Dodać linię',
      history: 'Historia',
      change: 'Zmienić',
      widget: 'Widżet',
      rename: 'Nazwać',
      delete: 'Usunąć'
    },
    add: {
      header: 'Dodanie nowej linii',
      bodyHead: 'Do urządzenia',
      bodyTail: ' zostanie dodana nowa linia. Wprowadź nazwę, opis i rodzaj.',
      labelName: 'Nazwa linii',
      labelDesc: 'Opis',
      labelType: 'Rodzaj linii',
      accept: 'Dodać'
    },
    edit: {
      header: 'Nazwać linię',
      bodyHead: 'Wprowadź nową nazwę dla linii',
      bodyTail: '.',
      labelName: 'Nazwa linii',
      labelDesc: 'Opis',
      accept: 'Nazwać'
    },
    value: {
      header: 'Zmiana wartości linii',
      bodyHead: 'Wprowadź nową wartość dla linii',
      bodyTail: '.',
      label: 'Wartość',
      accept: 'Zmień'
    },
    del: {
      header: 'Usunięcie linii',
      bodyHead: 'Linia',
      bodyTail: ' zostanie usunięta.',
      widgets: 'Ponadto usunięte zostaną następujące widżety:',
      accept: 'Usunąć'
    }
  },

  widget: {
    menu: {
      add: 'Dodać widżet',
      info: 'Info o widżecie',
      rename: 'Edytować widżet',
      remove: 'Usunąć widżet',
      bundle: 'Dodać paczkę',
      arrange: 'Ułożyć widżety'
    },
    info: {
      header: 'Info o widżecie',
      goTo: 'Do urządzenia'
    },
    add: {
      header: 'Dodanie nowego widżeta',
      bodyHead: 'Nowy widżet oparty na linii ',
      bodyTail:
        ' zostanie dodany do pulpitu. Wprowadź nazwę widżetu i wybierz jego rodzaj',
      labelName: 'Nazwa widżeta',
      labelType: 'Rodzaj widżeta',
      accept: 'Dodać'
    },
    create: {
      header: 'Tworzenie nowego widżetu',
      body: 'Aby utworzyć nowy widżet, należy wykonać kilka kroków. Pierwszym krokiem jest wybranie lokacji. Drugim krokiem jest wybór urządzenia. Trzeci krok to wybór linii. W ostatnim kroku należy wprowadzić nazwę widżetu i wybrać jego rodzaj.',
      labelName: 'Nazwa widżeta',
      labelType: 'Rodzaj widżeta',
      accept: 'Utwórz'
    },
    edit: {
      header: 'Aktualizacja widżeta',
      bodyHead: 'Wprowadź nową nazwę dla widżeta',
      bodyTail: '.',
      labelName: 'Nazwa widżeta',
      accept: 'Aktualizować'
    },
    del: {
      header: 'Usunięcie widżeta',
      bodyHead: 'Widżet',
      bodyTail: ' zostanie usunięty.',
      accept: 'Usunąć'
    },
    list: {
      ShowChart: 'Wykres liniowy',
      ShowValue: 'Obecna wartość',
      EditValue: 'Zmiana wartości'
    }
  },

  rule: {
    menu: {},
    errorMsg: {
      failAddRule: 'Nie udało się dodać nowej reguły!',
      failDelRule: 'Nie udało się usunąć reguły!',
      failInfRule: 'Nie udało się uzyskać informacji o regule!',
      failLinesIn: 'Nie udało się uzyskać listy linii wejściowych dla reguły!',
      failLinesOut: 'Nie udało się uzyskać listy linii wyjściowych dla reguły!',
      failLoadTypes: 'Nie udało się załadować listy typów reguł!'
    }
  },

  iface: {
    btn: {
      continue: 'Kontynuować',
      reject: 'Anulować',
      gotIt: 'Rozumiem',
      clear: 'Wyczyścić',
      save: 'Zapisać',
      back: 'Wróć',
      more: 'Więcej',
      ok: 'Ok'
    },
    tip: {
      owner: 'Powinieneś być właścicielem do udostępniania',
      inDev: 'Tymczasowo wyłączone. Funkcja jest w trakcie opracowywania.'
    },
    confirm: {
      irreversible: 'Jest to działanie nieodwracalne!',
      sure: 'Na pewno?'
    },
    search: {
      user: 'Wyszukaj użytkownika...',
      hint: 'Wpisz co najmniej 3 symbole',
      label: 'Szukaj'
    },
    filter: {
      title: 'Filtr',
      nodes: 'Tylko lokacje',
      shared: 'Współdzielone'
    },
    label: {
      date: 'Data',
      time: 'Czas',
      confirm: 'Kontrola'
    },
    level: {
      owner: 'Właściciel',
      admin: 'Admin',
      user: 'Użytkownik',
      none: 'Nie ma dostępu'
    },
    origin: {
      real: 'Rzeczywiste',
      virt: 'Wirtualne'
    },
    type: {
      toggle_switch: 'Przełącznik',
      rgb_color: 'Kolor RGB'
    },
    orgType: {
      home: 'Domowa',
      firm: 'Biznesowa'
    },
    prop: {
      org_id: 'ID grupy',
      id: 'ID',
      hwid: 'HWID',
      name: 'Nazwa',
      node: 'Węzeł',
      line: 'Linia',
      type: 'Rodzaj',
      group: 'Grupa',
      owner: 'Właściciel',
      device: 'Urządzenie',
      widget: 'Widżet',
      parent: 'Rodzic',
      source: 'Źródło',
      target: 'Przeznaczenie',
      location: 'Lokacja',
      access_level: 'Poziom dostępu',

      topic: 'Temat',
      value: 'Wartość',
      unit: 'J.m.',
      date: 'Data',
      time: 'Czas',
      level: 'Poziom',
      user: 'Użytkownik',
      rule: 'Reguła',
      error: 'Błąd',
      actions: 'Dział',
      group_pack: 'Właściciel, Grupa',

      title: 'Tytuł',
      component: 'Komponent',
      desc: 'Opis',

      device_name: 'Nazwa urządzenia',
      model_name: 'Nazwa modelu',
      hardware: 'Wersja sprzętu',
      software: 'Wersja programu',
      release: 'Wydanie',
      build: 'Tworzenie',
      manufacturer: 'Producent',
      power: 'Zasilanie',
      can_listen: 'Może słuchać',

      temperature: 'Temperatura',
      connection: 'Połączenie',
      rssi: 'RSSI',
      quality: 'Jakość',
      state: 'Stan',

      keep_alive: 'Utrzymać przy życiu',
      time_publish: 'Czas publikacji',
      battery_type: 'Rodzaj baterii',
      charging_current: 'Prąd ładowania'
    },
    err: {
      error: 'Błąd',
      goHome: 'Do strony głównej',
      require: 'To pole jest wymagane',
      space: 'Nie dopuszcza się spacji',
      empty: 'To pole nie może być puste',
      alphanum: 'Dozwolone są tylko litery łacińskie i cyfry',
      decimal: 'Dozwolone są tylko znaki dziesiętne',
      hexadec: 'Dozwolone są tylko znaki szesnastkowe',
      minHead: 'Dopuszcza się minimalnie',
      minTail: 'znaków',
      maxHead: 'Dopuszcza się maksymalnie',
      maxTail: 'znaków',
      minimum: 'Wartość minimalna:',
      maximum: 'Wartość maksymalna:',
      nothing: 'Nie ma tu nic',
      earlier: 'wcześniej niż początek',
      itself: 'Nie można przenieść węzła do samego siebie',
      areSame: 'Źródło i przeznaczenie są takie same',
      400: 'Błędne żądanie. Żądanie nie może zostać wykonane.',
      401: 'Nieautoryzowany dostęp. Wymagane jest uwierzytelnienie.',
      403: 'Zakazane. Serwer odrzuca żądanie.',
      404: 'Ta strona nie została znaleziona',
      405: 'Metoda żądania nie jest obsługiwana dla żądanego zasobu',
      500: 'Wewnętrzny błąd serwera',
      501: 'Nie wdrożono',
      502: 'Błąd bramy',
      503: 'Usługa niedostępna. Serwer nie jest w stanie zrealizować zapytania'
    }
  },

  err: {
    ACLexists: {
      title: 'Reguła dostępu już istnieje',
      detail: 'Reguła dostępu dla tego użytkownika już istnieje w tym węźle.'
    },
    Registered: {
      title: 'Już zarejestrowane',
      detail: 'To urządzenie jest już zarejestrowane'
    }
  }
};
