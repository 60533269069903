import { getErrorResponse } from '~/utils/apiUtils';

// https://kp-staging.lanet.house/public/#api-ACL
// Access Control List (ACL)
export default ($axios) => ({
  // https://kp-staging.lanet.house/public/#api-ACL-PostOrgsAcl
  // Create an access rule for a user
  async create(orgId, userId, node, level) {
    try {
      const { status, data, errors } = await $axios.$post(
        `/v1/org/${orgId}/acl`,
        {
          user_id: userId,
          location: node,
          access_level: level
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-ACL-DeleteOrgsAcl
  // Cancel a user access rule.
  async delete(orgId, aclId) {
    try {
      const { status, data, errors } = await $axios.$delete(
        `/v1/org/${orgId}/acl/${aclId}`
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-ACL-GetOrgAcls
  // Get a list of access rules for users in the group
  async list(orgId, options = {}) {
    const {
      location,
      include,
      pageNumber,
      pageSize = 100,
      pageOffset,
      pageLimit
    } = options;

    try {
      const { status, data, included, meta, errors } = await $axios.$get(
        `/v1/org/${orgId}/acl`,
        {
          params: {
            location,
            include,
            page_number: pageNumber,
            page_size: pageSize,
            page_offset: pageOffset,
            page_limit: pageLimit
          }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          included,
          meta,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  }
});
