import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2e38cd48"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=2e38cd48&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBar: require('/builds/iot/controller/lisa-back-html/components/AppBar.vue').default,NavMenu: require('/builds/iot/controller/lisa-back-html/components/NavMenu.vue').default,NodeTree: require('/builds/iot/controller/lisa-back-html/components/Node/Tree/index.vue').default,Notification: require('/builds/iot/controller/lisa-back-html/components/Notification.vue').default})
