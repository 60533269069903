import locale77427e78 from '../../locales/en.js'
import locale7822996b from '../../locales/uk.js'
import locale77dc9845 from '../../locales/pl.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: false,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","name":"English","iso":"en-US","icon":"gb","file":"en.js"},{"code":"uk","name":"Українська","iso":"uk-UA","icon":"ua","file":"uk.js"},{"code":"pl","name":"Polski","iso":"pl","icon":"pl","file":"pl.js"}],
  defaultLocale: "uk",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "/builds/iot/controller/lisa-back-html/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: true,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","name":"English","iso":"en-US","icon":"gb","file":"en.js"},{"code":"uk","name":"Українська","iso":"uk-UA","icon":"ua","file":"uk.js"},{"code":"pl","name":"Polski","iso":"pl","icon":"pl","file":"pl.js"}],
  localeCodes: ["en","uk","pl"],
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'uk.js': () => Promise.resolve(locale7822996b),
  'pl.js': () => Promise.resolve(locale77dc9845),
}
