import { getErrorResponse } from '~/utils/apiUtils';

// https://kp-staging.lanet.house/public/#api-Rules
export default ($axios) => ({
  // https://kp-staging.lanet.house/public/#api-Rules-GetMeInventory
  // Create a new rule
  async create(orgId) {
    try {
      const { status, data, errors } = await $axios.$post(
        `/v1/org/${orgId}/rule`,
        {}
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Rules-DeleteRule
  // Delete a rule
  async delete(orgId, ruleId) {
    try {
      const { status, data, errors } = await $axios.$delete(
        `/v1/org/${orgId}/rules`,
        {
          params: {
            ruleId
          }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Rules-GetRule
  // Get the rule's info
  async info(orgId, ruleId) {
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/rules`,
        {
          params: {
            ruleId
          }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Rules-GetRulesOutputLines
  // Get a list of available output lines for the rule
  async outlines(orgId, nodeId, ruleType, outLineId) {
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/rules/outputlines`,
        {
          params: {
            node: nodeId,
            rule_type: ruleType,
            output_id: outLineId
          }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Rules-GetRulesInputLines
  // Get a list of available input lines for the rule
  async inlines(orgId, nodeId, ruleType, inLineId) {
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/rules/inputlines`,
        {
          params: {
            node: nodeId,
            rule_type: ruleType,
            input_id: inLineId
          }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Rules-GetRulesTypes
  // Get a list of available rule types
  async ruletypes(orgId) {
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/rules/types`,
        {}
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  }
});
