// eslint-disable-next-line no-unused-vars
import { unWrap, getErrorResponse } from '~/utils/apiUtils';

// https://kp-staging.lanet.house/public/#api-Helper
export default ($axios) => ({
  // https://kp-staging.lanet.house/public/#api-Helper-GetUsersFindByID
  // Find user by ID
  async findById(userId) {
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/finduser/${userId}`
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Helper-GetUsersFindByName
  // Find user by name or email
  async findByName(name) {
    try {
      const { status, data, errors } = await $axios.$get(`/v1/finduser`, {
        params: { name }
      });
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  }
});
