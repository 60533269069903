
export default {
  name: 'Cell',

  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
