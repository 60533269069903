
export default {
  name: 'Avatar',

  props: {
    icon: {
      type: String,
      default: ''
    }
  },

  computed: {
    isIcon() {
      return this.icon.startsWith('mdi-');
    }
  }
};
