
export default {
  name: 'Table',

  props: {
    table: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    menu: {
      type: Array,
      default: () => []
    },
    isItemOff: {
      type: Array,
      default: () => []
    },
    sample: {
      type: String,
      default: ''
    },
    attr: {
      type: String,
      default: ''
    },
    padding: {
      type: String,
      default: ''
    },
    pointer: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    cellStyle() {
      return {
        paddingRight: `${this.padding} !important`,
        paddingLeft: `${this.padding} !important`
      };
    }
  },

  methods: {
    call(func, item) {
      if (func) this.$emit(func, item);
    }
  }
};
