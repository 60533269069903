/* eslint-disable no-console */
import { getOrgId, getHWID, logger } from '~/utils/helpers'; // getParent,

export const state = () => ({
  currentRule: {
    id: null,
    type: 'rule',
    attributes: {}
  },
  ruleTypes: []
});

export const getters = {
  getRule: (s) => s.currentRule,
  getRuleTypes: (s) => s.ruleTypes
};

export const mutations = {
  setRule(state, rule) {
    if (Object.keys(rule).length === 0 && rule.constructor === Object) {
      state.currentRule = {
        id: null,
        type: 'device',
        attributes: {}
      };
    } else {
      state.currentRule = rule;
    }
    logger('<[ STORE ]>', 'SET CUR RULE', state.currentRule.id);
  },

  setRuleTypes(state, types) {
    state.ruleTypes = [...types];
    logger('<[ STORE ]>', 'SET RULE TYPES', state.ruleTypes);
  }
};

export const actions = {
  // Create a new rule
  async create({ dispatch, commit }, node) {
    try {
      let rule = {};
      const orgId = getOrgId(node);
      const api = this.$lisaAPI.rule;
      const res = await api.create(orgId);
      if (res.status === 'ok' && res.data) {
        rule = res.data[0];
        logger('<[ STORE ]>', 'CREATE RULE', rule.id);
        await commit('setRule', rule);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Rule',
            errorText: 'rule.errorMsg.failAddRule'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      // await dispatch('location/loadList', orgId, { root: true });
      // await dispatch('location/find', rule ? rule.id : node, { root: true });
    } catch (error) {
      console.error('Store/Rule/create', error.message);
    }
  },

  // Delete a rule
  async delete({ dispatch, commit }, node) {
    try {
      const orgId = getOrgId(node);
      const ruleId = getHWID(node);
      // const parentId = getParent(node);
      const api = this.$lisaAPI.rule;
      const res = await api.delete(orgId, ruleId);
      if (res.status === 'ok') {
        logger('<[ STORE ]>', 'DELETE RULE', node);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Rule',
            errorText: 'rule.errorMsg.failDelRule'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      // await dispatch('location/loadList', orgId, { root: true });
      // await dispatch('location/find', parentId, { root: true });
      // await dispatch('acl/list', orgId, { root: true });
    } catch (error) {
      console.error('Store/Rule/delete', error.message);
    }
  },

  // Get info of rule
  async info({ commit }, node) {
    try {
      const orgId = getOrgId(node);
      const ruleId = getHWID(node);
      const api = this.$lisaAPI.rule;
      const res = await api.info(orgId, ruleId);
      if (res.status === 'ok') {
        const rule = res.data[0];
        logger('<[ STORE ]>', 'RULE INFO', rule);
        await commit('setRule', rule);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Rule',
            errorText: 'rule.errorMsg.failInfRule'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Rule/info', error.message);
    }
  },

  // Get a list of available output lines for the rule
  async outlines({ commit }, node, ruleType, outLineId) {
    try {
      const orgId = getOrgId(node);
      const api = this.$lisaAPI.rule;
      const res = await api.outlines(orgId, node, ruleType, outLineId);
      if (res.status === 'ok') {
        const lines = res.data[0];
        logger('<[ STORE ]>', 'OUTPUT LINES', lines);
        // await commit('set???', lines);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Rule',
            errorText: 'rule.errorMsg.failLinesOut'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Rule/outlines', error.message);
    }
  },

  // Get a list of available input lines for the rule
  async inlines({ commit }, node, ruleType, inLineId) {
    try {
      const orgId = getOrgId(node);
      const api = this.$lisaAPI.rule;
      const res = await api.inlines(orgId, node, ruleType, inLineId);
      if (res.status === 'ok') {
        const lines = res.data[0];
        logger('<[ STORE ]>', 'INPUT LINES', lines);
        // await commit('set???', lines);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Rule',
            errorText: 'rule.errorMsg.failLinesIn'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Rule/inlines', error.message);
    }
  },

  // Load a list of available rule types
  async loadRuleTypes({ commit }, orgId) {
    try {
      const api = this.$lisaAPI.rule;
      const res = await api.ruletypes(orgId);
      console.log('Result:', res);
      if (res.status === 'ok' && res.data) {
        logger('<[ STORE ]>', 'LOAD RULE TYPES', res.data);
        await commit('setRuleTypes', res.data);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Rule',
            errorText: 'rule.errorMsg.failLoadTypes'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Rule/loadRuleTypes', error.message);
    }
  }
};
