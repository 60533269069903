
import { mapGetters } from 'vuex';

export default {
  name: 'AppBar',

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      hasToken: 'hasToken'
    }),

    showOptions() {
      return this.$vuetify.breakpoint.smAndUp || !this.hasToken;
    },

    env() {
      return process.env.NODE_ENV;
    }
  }
};
