
export default {
  name: 'ButtonAccept',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
