
import { mapActions } from 'vuex';
import { logger } from '~/utils/helpers';

export default {
  name: 'EditGroup',

  props: {
    enable: Boolean,
    group: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      orgName: '',
      orgDesc: '',
      orgIcon: '',
      orgType: null,
      errName: false,
      errDesc: false,
      errIcon: false,
      errType: false,
      touchName: false,
      touchDesc: false,
      touchIcon: false
    };
  },

  computed: {
    isNew() {
      return !this.group || Object.keys(this.group).length === 0;
    },

    texts() {
      if (this.isNew)
        return {
          header: this.$t('group.add.header'),
          body: this.$t('group.add.bodyHead') + this.$t('group.add.bodyTail'),
          labelId: this.$t('group.add.labelId'),
          labelType: this.$t('group.add.labelType'),
          labelName: this.$t('group.add.labelName'),
          labelDesc: this.$t('group.add.labelDesc'),
          labelIcon: this.$t('group.add.labelIcon'),
          reject: this.$t('iface.btn.reject'),
          accept: this.$t('group.add.accept')
        };
      else
        return {
          header: this.$t('group.edit.header'),
          body:
            this.$t('group.edit.bodyHead') +
            ` "${this.group?.attributes.name}" (${this.group?.id}) ` +
            this.$t('group.edit.bodyTail'),
          labelId: this.$t('group.edit.labelId'),
          labelType: this.$t('group.edit.labelType'),
          labelName: this.$t('group.edit.labelName'),
          labelDesc: this.$t('group.edit.labelDesc'),
          labelIcon: this.$t('group.add.labelIcon'),
          reject: this.$t('iface.btn.reject'),
          accept: this.$t('group.edit.accept')
        };
    },

    rulesName() {
      const rules = [];
      const min = 3;
      const max = 128;

      {
        const rule = (v) => !!v || this.$t('iface.err.require');
        rules.push(rule);
      }
      {
        const rule = (v) => !!v.trim().length || this.$t('iface.err.empty');
        rules.push(rule);
      }
      {
        const rule = (v) =>
          (v || '').length >= min ||
          this.$t('iface.err.minHead') +
            ` ${min} ` +
            this.$t('iface.err.minTail');
        rules.push(rule);
      }
      {
        const rule = (v) =>
          (v || '').length <= max ||
          this.$t('iface.err.maxHead') +
            ` ${max} ` +
            this.$t('iface.err.maxTail');

        rules.push(rule);
      }

      return rules;
    },
    rulesDesc() {
      const rules = [];
      const max = 512;

      {
        const rule = (v) =>
          (v || '').length <= max ||
          this.$t('iface.err.maxHead') +
            ` ${max} ` +
            this.$t('iface.err.maxTail');

        rules.push(rule);
      }

      return rules;
    },
    rulesIcon() {
      const rules = [];
      const max = 255;

      {
        const rule = (v) =>
          (v || '').length <= max ||
          this.$t('iface.err.maxHead') +
            ` ${max} ` +
            this.$t('iface.err.maxTail');

        rules.push(rule);
      }

      return rules;
    },
    rulesType() {
      const rules = [];

      {
        const rule = (v) => !!v || this.$t('iface.err.require');
        rules.push(rule);
      }

      return rules;
    },

    isFormOk() {
      const isTouched = this.touchName || this.touchDesc || this.touchIcon;
      const isTypeOk = !!this.orgType;
      const isNameOk = this.orgName && !this.errName;
      const isDescOk = !this.errDesc;
      const isIconOk = !this.errIcon;
      return isTouched && isTypeOk && isNameOk && isDescOk && isIconOk;
    }
  },

  watch: {
    orgName() {
      this.checkFields();
    },
    orgDesc() {
      this.checkFields();
    },
    orgIcon() {
      this.checkFields();
    }
  },

  mounted() {
    this.initFields();
  },

  updated() {
    this.initFields();
    this.$refs.orgName?.resetValidation();
    this.$refs.orgDesc?.resetValidation();
    this.$refs.orgIcon?.resetValidation();
    this.$refs.orgType?.resetValidation();
  },

  methods: {
    ...mapActions({
      createOrg: 'organization/create',
      updateOrg: 'organization/update'
    }),

    initFields() {
      if (this.isNew) {
        this.orgName = '';
        this.orgDesc = '';
        this.orgIcon = '';
        this.orgType = null;
      } else {
        this.orgName = this.group.attributes.name;
        this.orgDesc = this.group.attributes.description;
        this.orgIcon = this.group.attributes.avatar;
        this.orgType = this.group.attributes.type;
      }
    },
    checkFields() {
      this.touchName = !(this.orgName === this.group?.attributes?.name);
      this.touchDesc = !(this.orgDesc === this.group?.attributes?.description);
      this.touchIcon = !(this.orgIcon === this.group?.attributes?.avatar);
      this.errName = !this.$refs.orgName?.validate(true);
      this.errDesc = !this.$refs.orgDesc?.validate(true);
      this.errIcon = !this.$refs.orgIcon?.validate(true);
      this.errType = !this.$refs.orgType?.validate(true);
    },

    submit() {
      if (this.isNew) this.append();
      else this.update();
    },
    append() {
      this.createOrg({
        type: this.orgType,
        name: this.orgName,
        description: this.orgDesc,
        avatar: this.orgIcon
      });
      logger(':+: :+: :+:', 'APPEND GROUP', this.orgName);
      this.emit('close');
    },
    update() {
      this.updateOrg({
        id: this.group.id,
        name: this.orgName,
        description: this.orgDesc,
        avatar: this.orgIcon
      });
      logger(':*: :*: :*:', 'UPDATE GROUP', this.orgName);
      this.emit('close');
    },

    emit(e) {
      if (e) this.$emit(e);
    }
  }
};
