import { render, staticRenderFns } from "./List.vue?vue&type=template&id=1cb73135"
import script from "./List.vue?vue&type=script&lang=js"
export * from "./List.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GroupAvatar: require('/builds/iot/controller/lisa-back-html/components/Group/Avatar.vue').default,DialogEditGroup: require('/builds/iot/controller/lisa-back-html/components/Dialog/EditGroup.vue').default,DialogDelGroup: require('/builds/iot/controller/lisa-back-html/components/Dialog/DelGroup.vue').default,GroupTable: require('/builds/iot/controller/lisa-back-html/components/Group/Table.vue').default})
