import { render, staticRenderFns } from "./Union.vue?vue&type=template&id=310344e6&scoped=true"
import script from "./Union.vue?vue&type=script&lang=js"
export * from "./Union.vue?vue&type=script&lang=js"
import style0 from "./Union.vue?vue&type=style&index=0&id=310344e6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310344e6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Avatar: require('/builds/iot/controller/lisa-back-html/components/Avatar.vue').default})
