/* eslint-disable no-console */
import { logger } from '~/utils/helpers';

export const state = () => ({
  currentOrg: {
    id: null,
    type: '',
    attributes: {
      type: '',
      name: '',
      description: '',
      avatar: null
    }
  },
  orgsList: [],
  orgsOwnerList: []
});

export const getters = {
  getCurrent: (s) => s.currentOrg,
  getList: (s) => s.orgsList,
  getOwnerList: (s) => s.orgsOwnerList,
  getOrgOwnerById: (s) => (id) => {
    return s.orgsOwnerList.find((el) => el.id === id);
  }
};

export const mutations = {
  setCurrent(state, currentOrg) {
    if (!currentOrg) return;
    if (
      Object.keys(currentOrg).length === 0 &&
      currentOrg.constructor === Object
    ) {
      // Set default values
      state.currentOrg = {
        id: null,
        type: '',
        attributes: {
          type: '',
          name: '',
          description: '',
          avatar: ''
        }
      };
    } else {
      state.currentOrg = currentOrg;
    }
    logger('<[ STORE ]>', 'SET CURR ORG', currentOrg.id);
  },

  setList(state, orgsList) {
    state.orgsList = [...orgsList];
    logger('<[ STORE ]>', 'SET ORG LIST', orgsList);
  },

  setOwnerList(state, orgsOwnerList) {
    state.orgsOwnerList = [...orgsOwnerList];
    // logger('<[ STORE ]>', 'SET OWNERS', orgsOwnerList);
  }
};

export const actions = {
  // Set current organisation by default first item in list getters.getList[0]
  setCurrentByDefault({ commit, getters }) {
    let currentOrg = {};
    // if list isn't empty, then set first element as default
    // if (getters.getList.length > 0 && getters.getCurrent.id === null) {
    if (getters.getList && !getters.getCurrent.id) {
      currentOrg = getters.getList[0];
    } else {
      // loading current organization from updated list
      currentOrg = getters.getList.find(
        (item) => item.id === getters.getCurrent.id
      );
    }
    logger('<[ STORE ]>', 'DEFAULT ORG', currentOrg?.id);
    return commit('setCurrent', currentOrg);
  },

  // Load groups list by API and prepare list for treeView
  async loadList({ commit, getters }) {
    try {
      const api = this.$lisaAPI.org;
      const res = await api.list();
      if (res.status === 'ok') {
        // Check array with "orgs" and add owner's info to object "relationships.owner" from "included"
        if (res.data.length !== 0 && Object.keys(res).includes('included')) {
          await commit('setOwnerList', res.included);
          for (const item of res.data) {
            if (Object.keys(item).includes('relationships')) {
              item.relationships.owner = getters.getOrgOwnerById(
                item.relationships.owner.data.id
              );
            } else {
              item.relationships = {
                owner: {
                  id: 0,
                  attributes: {
                    avatar: '',
                    email: '',
                    firstname: '',
                    lastname: '',
                    nickname: ''
                  }
                }
              };
            }
          }
        }
        // logger('<[ STORE ]>', 'LOAD ORG LIST', res.data);
        await commit('setList', res.data);
        // await dispatch('setCurrentByDefault');
      } else {
        await commit('setList', []);
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Organization',
            errorText: 'group.errorMsg.failGetOrgs'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Organization/loadList', error.message);
    }
  },

  // Create a new group
  async create({ dispatch, commit }, { type, name, description, avatar }) {
    try {
      const api = this.$lisaAPI.org;
      const res = await api.create(type, name, description, avatar);
      if (res.status === 'ok' && res.data) {
        const org = res.data[0];
        logger('<[ STORE ]>', 'CREATE ORG', org.id);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Organization',
            errorText: 'group.errorMsg.failAddOrg'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('loadList');
    } catch (error) {
      console.error('Store/Organization/create', error.message);
    }
  },

  // Update a group
  async update({ dispatch, commit }, { id, name, description, avatar }) {
    try {
      const api = this.$lisaAPI.org;
      const res = await api.update(id, name, description, avatar);
      if (res.status === 'ok') {
        logger('<[ STORE ]>', 'UPDATE ORG', id);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Organization',
            errorText: 'group.errorMsg.failUpdOrg'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('loadList');
    } catch (error) {
      console.error('Store/Organization/update', error.message);
    }
  },

  // Delete a group
  async delete({ getters, dispatch, commit }, id) {
    try {
      const api = this.$lisaAPI.org;
      const res = await api.delete(id);
      if (res.status === 'ok') {
        logger('<[ STORE ]>', 'DELETE ORG', id);
        if (getters.getCurrent?.id === id && getters.getList)
          if (getters.getList[0].id !== id)
            await commit('setCurrent', getters.getList[0]);
          else if (getters.getList.length > 1)
            await commit('setCurrent', getters.getList[1]);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Organization',
            errorText: 'group.errorMsg.failDelOrg'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('loadList');
    } catch (error) {
      console.error('Store/Organization/delete', error.message);
    }
  }
};
