
export default {
  layout: 'empty',

  props: {
    error: {
      type: Object,
      default: null
    }
  },

  data() {
    return {};
  },

  head() {
    const title = `${this.$t(`iface.err.error`)} ${this.error.statusCode}`;
    return {
      title
    };
  },

  computed: {
    message() {
      const name = `iface.err.${this.error.statusCode}`;
      return this.$te(name) ? this.$t(name) : this.error.message;
    }
  }
};
