import { getErrorResponse } from '~/utils/apiUtils';

// https://kp-staging.lanet.house/public/#api-Orgs
export default ($axios) => ({
  // https://kp-staging.lanet.house/public/#api-Orgs-PostOrgs
  // Create a new organization
  async create(type, name, desc, avatar) {
    try {
      const { status, data, errors } = await $axios.$post('/v1/org', {
        type,
        name,
        desc,
        avatar
      });
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Orgs-PatchOrgs
  // Update an organization
  async update(id, name, desc, avatar) {
    try {
      const { status, data, errors } = await $axios.$patch(`/v1/org/${id}`, {
        name,
        desc,
        avatar
      });
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Orgs-DeleteOrgs
  // Delete an organization
  async delete(id) {
    try {
      const { status, data, errors } = await $axios.$delete(`/v1/org/${id}`);
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Orgs-GetOrgs
  // Get user's organizations list
  async list() {
    try {
      const { status, data, included, errors } = await $axios.$get('/v1/org', {
        params: { include: 'owner,isowner' }
      });
      return {
        status,
        data,
        included,
        errors
      };
      // throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  }
});
