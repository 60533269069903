import vuetifyUk from 'vuetify/es5/locale/uk';
export default {
  $vuetify: { ...vuetifyUk },

  appName: 'lanet.house',

  auth: {
    title: 'Вхід',
    errorMsg: {
      failLoadIdentityInfo:
        'Виникла помилка під час отримання даних користувача!',
      failLogout: 'Помилка виходу з системи!',
      axios: 'Axios error!'
    },
    label: {
      login: 'Логін',
      logout: 'Пароль'
    },
    btn: {
      login: 'Увійти',
      logout: 'Вийти'
    },
    message: {
      nameRequired: "Обов'язкове поле",
      nameLess: 'Логін повинен містити менше 16 символів',
      passRequired: "Обов'язкове поле",
      namePassWrong: 'Введені логін та пароль не збігаються',
      accessGranted: 'Доступ схвалено'
    },
    errorMessage: {
      groupsHomeAPI: 'Не вдалось отримати дані від сервера!',
      groupsHomeAPIAdd: 'Не вдалось додати запис!'
    }
  },

  profile: {
    errorMsg: {
      failGetLog: 'Виникла помилка під час отримання даних журналу подій!',
      axios: 'Axios error!'
    },
    menu: {
      edit: 'Редагувати профіль',
      logout: 'Вийти з системи',
      chooseLang: 'Вибрати мову',
      activityLog: 'Журнал подій'
    },
    theme: {
      light: 'Світла тема',
      dark: 'Темна тема'
    },
    activityLogTable: {
      title: 'Журнал подій',
      footerProps: {
        itemsPerPageText: 'Подій на сторінці',
        itemsPerPageAllText: 'Усі',
        pageText: 'з'
      },
      id: 'Код',
      timeStamp: 'Час події',
      operation: 'Операція',
      description: 'Опис',
      actions: 'Дії',
      label: {
        filterDates: 'Фільтр по датах',
        filterOp: 'Фільтр по операціях',
        search: 'Пошук в описі'
      },
      datePicker: {
        btn: {
          apply: 'Застосувати',
          cancel: 'Відмінити'
        }
      }
    }
  },

  navMenu: {
    dashboard: 'Дашборд',
    controllers: 'Контролери',
    devices: 'Пристрої',
    groups: 'Групи',
    groupsMenu: {
      home: 'Домашні',
      corp: 'Корпоративні'
    },
    home: 'Домашні',
    corp: 'Організації',
    users: 'Користувачі'
  },

  dashboard: {
    title: 'Дашборд',
    subtitle: '',
    label: {},
    welcome: {
      title: 'Ласкаво просимо в lanet.house!',
      subtitle:
        'Невдовзі тут з`явиться дашборд для зручного користування вибраними пристроями'
    },
    btn: {
      addGroup: 'Додати панель'
    },
    message: {
      noGroupsFound: 'Не знайдено жодної панелі'
    }
  },

  controllers: {
    title: 'Контролери',
    subtitle: 'контролери та координатори',
    label: {},
    btn: {
      addDevice: 'Додати пристрій'
    },
    message: {
      noDevicesFound: 'Не знайдено жодного пристрою'
    }
  },

  devices: {
    title: 'Пристрої',
    subtitle: 'cенсори та актуатори',

    label: {},
    btn: {
      addDevice: 'Додати пристрій'
    },

    // errorMsg: {
    //   failAddDev: 'Виникла помилка під час створення нового пристрою!',
    //   failDelDev: 'Виникла помилка під час видалення пристрою!',
    //   failUpdDev: 'Виникла помилка під час оновлення пристрою!',
    //   failStaDev: 'Виникла помилка під час отримання стану пристрою!',
    //   failAddVirt: 'Виникла помилка під час створення віртуального пристрою!',
    //   failAddLine: 'Виникла помилка під час додавання нової лінії до пристрою!',
    //   failDelLine: 'Виникла помилка під час видалення лінії з пристрою!',
    //   failGetValue: 'Виникла помилка під час отримання значення лінії!',
    //   failSetValue: 'Виникла помилка під час збереження значення лінії!',
    //   failLoadTypes: 'Виникла помилка під час отримання списку типів ліній!',
    //   failGetSetting: 'Виникла помилка під час отримання налаштувань пристрою!',
    //   failSetSetting: 'Виникла помилка під час збереження налаштувань пристрою!'
    // },

    message: {
      noDevicesFound: 'Не знайдено жодного пристрою'
    }
  },

  // orgs: {
  //   title: 'Оберіть організацію чи групу',
  //   subtitle: 'корпоративні та домашні',

  //   errorMsg: {
  //     failGetOrgs: 'Виникла помилка під час отримання даних про групи!',
  //     failAddOrg: 'Виникла помилка під час створеня нової групи!',
  //     failDelOrg: 'Виникла помилка під час видалення групи!',
  //     failUpdOrg: 'Виникла помилка під час оновлення групи!'
  //   },

  //   message: {
  //     noGroupsFound: 'Не знайдено жодної групи',
  //     noOwner: 'Інформація недоступна'
  //   },

  //   menu: {
  //     edit: 'Налаштування',
  //     select: 'Оберіть групу'
  //   },

  //   btn: {
  //     newOrg: 'Створити',
  //     cancel: 'Відмінити',
  //     choose: 'Обрати'
  //   },

  //   table: {
  //     title: 'Мої групи',
  //     lblSearch: 'Знайти',
  //     footerProps: {
  //       itemsPerPageText: 'Груп на сторінці',
  //       itemsPerPageAllText: 'Усі',
  //       pageText: 'з'
  //     },
  //     id: 'Код',
  //     orgAvatar: 'Аватар',
  //     owner: 'Власник',
  //     name: 'Назва',
  //     type: 'Тип',
  //     description: 'Опис',
  //     actions: 'Дії'
  //   }
  // },

  // editOrg: {
  //   title: 'Налаштування',
  //   subtitle: 'групи чи організації',

  //   tooltip: {
  //     refreshForm: 'Оновити форму'
  //   },
  //   rulesMsg: {
  //     avatarSize: 'Розмір файлу має бути менше 2 MB!',
  //     required: "Це обов'язкове поле!",
  //     nameLen: 'Назва має містити від 3 до 25 символів!',
  //     descLen: 'Опис має містити менше 255 символів!'
  //   },

  //   btn: {
  //     save: 'Зберегти',
  //     delete: 'Видалити',
  //     cancel: 'Відмінити'
  //   },

  //   form: {
  //     lblOrgId: 'Код групи',
  //     phOrgId: 'Код',
  //     lblOrgAvatar: 'Аватар',
  //     phOrgAvatar: 'Оберіть файл з аватаром',
  //     lblOrgType: 'Тип групи',
  //     phOrgType: 'Оберіть...',
  //     lblOrgName: 'Назва групи',
  //     phOrgName: "ТОВ 'Мережа Ланет'",
  //     lblOrgDescription: 'Опис групи',
  //     phOrgDescription: 'ISP провайдер',

  //     orgTypes: {
  //       home: 'Домашня',
  //       business: 'Організація'
  //     }
  //   }
  // },

  // location: {
  //   title: 'Оберіть локацію',
  //   subtitle: '',

  //   errorMsg: {
  //     failGetLoc: 'Виникла помилка під час отримання даних про локації!',
  //     failAddLoc: 'Виникла помилка під час створеня нової локації!',
  //     failDelLoc: 'Виникла помилка під час видалення локації!',
  //     failUpdLoc: 'Виникла помилка під час оновлення локації!'
  //   },

  //   message: {
  //     noLocationsFound: 'Не знайдено жодної локації'
  //   },

  //   lblSearch: 'Знайти',

  //   menu: {
  //     edit: 'Налаштування',
  //     select: 'Оберіть...'
  //   },

  //   btn: {
  //     newLoc: 'Створити',
  //     cancel: 'Відмінити',
  //     choose: 'Обрати'
  //   }
  // },

  group: {
    title: 'Групи',
    btn: {
      add: 'Додати групу'
    },
    menu: {
      select: 'Обрати групу',
      change: 'Змінити групу',
      remove: 'Видалити групу'
    },
    add: {
      header: 'Додавання нової групи',
      bodyHead: 'Буде створено нову групу',
      bodyTail: '. Оберіть тип групи і введіть назву та опис групи.',
      labelId: 'ID групи',
      labelType: 'Тип групи',
      labelName: 'Назва групи',
      labelDesc: 'Опис групи',
      labelIcon: 'Аватар групи',
      accept: 'Додати'
    },
    edit: {
      header: 'Редагування групи',
      bodyHead: 'Введіть нову назву для групи',
      bodyTail: '. Також можна змінити опис групи.',
      labelId: 'ID групи',
      labelType: 'Тип групи',
      labelName: 'Назва групи',
      labelDesc: 'Опис групи',
      labelIcon: 'Аватар групи',
      accept: 'Змінити'
    },
    del: {
      header: 'Видалення групи',
      bodyHead: 'Групу',
      bodyTail: 'буде видалено.',
      notEmpty: 'Слід спершу видалити всі дочірні локації, пристрої і правила',
      accept: 'Видалити'
    },
    errorMsg: {
      failGetOrgs: 'Виникла помилка під час отримання даних про групи!',
      failAddOrg: 'Виникла помилка під час створеня нової групи!',
      failDelOrg: 'Виникла помилка під час видалення групи!',
      failUpdOrg: 'Виникла помилка під час оновлення групи!'
    }
  },

  acl: {
    menu: {
      remove: 'Видалити правило'
    },
    errorMsg: {
      failAddACL: 'Виникла помилка під час створеня нового правила доступу!',
      failDelACL: 'Виникла помилка під час видалення правила доступу!',
      failLstACL: 'Виникла помилка під час отримання списку правил доступу!',
      notOwner: 'Не можна обирати власника',
      double: 'Правило доступу для цього користувача вже існує на цьому вузлі'
    },
    del: {
      header: 'Видалення правила доступу',
      bodyHead: 'Правило доступу',
      bodyChest: 'до вузла',
      bodyWaist: 'для',
      bodyTail: 'буде видалено. Ця дія незворотня.',
      accept: 'Видалити'
    }
  },

  node: {
    title: 'Локація',
    btn: {
      addNode: 'Локація',
      addDev: 'Пристрій',
      addRule: 'Правило'
    },
    menu: {
      addNode: 'Додати локацію',
      editNode: 'Найменувати локацію',
      shareNode: 'Поділитися локацією',
      moveNode: 'Перенести локацію',
      delNode: 'Видалити локацію',
      addDev: 'Додати пристрій',
      addRule: 'Додати правило'
    },
    tip: {
      isRoot: 'Не можна видаляти кореневу локацію',
      notEmpty: 'Слід спершу видалити дочірні'
    },
    tab: {
      info: 'Інфо',
      child: 'Дочірні',
      access: 'Доступ'
    },
    confirm: {
      sure: 'Ви впевнені?'
    },
    add: {
      header: 'Додавання нової локації',
      bodyHead: 'До локації ',
      bodyTail: ' буде додано нову дочірню локацію.',
      label: 'Назва локації',
      accept: 'Додати'
    },
    edit: {
      header: 'Зміна назви локації',
      bodyHead: 'Введіть нову назву для локації',
      bodyTail: '.',
      label: 'Назва локації',
      accept: 'Змінити'
    },
    move: {
      header: 'Перенесення локації',
      bodyHead: 'Локацію ',
      bodyTail: 'буде перенесено. Оберіть нове розташування.',
      label: 'Нове розташування',
      accept: 'Перенести'
    },
    share: {
      header: 'Доступ до локації',
      bodyHead:
        'Введіть ім`я або email користувача, з яким ви хочете поділитися локацією',
      bodyTail: '. Також вкажіть рівень доступу для цього користувача.',
      label: 'Ім`я або email користувача',
      noData: 'Пошук користувача, з яким ви хочете поділитися локацією',
      accept: 'Поділитися'
    },
    del: {
      header: 'Видалення локації',
      bodyHead: 'Локацію',
      bodyTail: 'буде видалено.',
      accept: 'Видалити'
    },
    is: {
      shared: 'Цю локацію надано у спільний доступ'
    },
    errorMsg: {
      failGetLoc: 'Виникла помилка під час отримання даних про локації!',
      failAddLoc: 'Виникла помилка під час створеня нової локації!',
      failUpdLoc: 'Виникла помилка під час оновлення локації!',
      failDelLoc: 'Виникла помилка під час видалення локації!'
    }
  },

  device: {
    title: 'Пристрій',
    btn: {
      refresh: 'Оновити',
      edit: 'Назвати',
      remove: 'Видалити',
      table: 'Таблиця',
      chart: 'Графік',
      hideOpt: 'Сховати параметри',
      showOpt: 'Показати параметри',
      repeatOn: 'Повтор',
      repeatOff: 'Припинити'
    },
    menu: {
      edit: 'Найменувати пристрій',
      share: 'Поділитися пристроєм',
      move: 'Перенести пристрій',
      remove: 'Видалити пристрій'
    },
    tab: {
      info: 'Інфо',
      lines: 'Лінії',
      config: 'Конфіг',
      status: 'Статус',
      errors: 'Помилки',
      access: 'Доступ',
      setup: 'Налаштування'
    },
    confirm: {
      sure: 'Ви впевнені?'
    },
    add: {
      header: 'Додавання нового пристрою',
      bodyHead: 'До локації',
      bodyTail:
        ' буде додано новий пристрій. Введіть назву пристрою і оберіть його тип. Також, за необхідності, введіть ідентифікатор пристрою (HWID).',
      labelId: 'HWID пристрою',
      labelName: 'Назва пристрою',
      labelBroker: 'MQTT брокер',
      accept: 'Додати'
    },
    edit: {
      header: 'Зміна назви пристрою',
      bodyHead: 'Введіть нову назву для пристрою',
      bodyTail: '.',
      labelId: 'HWID пристрою',
      labelName: 'Назва пристрою',
      accept: 'Змінити'
    },
    move: {
      header: 'Перенесення пристрою',
      bodyHead: 'Пристрій ',
      bodyTail: 'буде перенесено. Оберіть нове розташування.',
      label: 'Нове розташування',
      accept: 'Перенести'
    },
    share: {
      header: 'Доступ до пристрою',
      bodyHead:
        'Введіть ім`я або email користувача, з яким ви хочете поділитися пристроєм',
      bodyTail: '. Також вкажіть рівень доступу для цього користувача.',
      label: 'Ім`я або email користувача',
      noData: 'Пошук користувача, з яким ви хочете поділитися пристроєм',
      accept: 'Поділитися'
    },
    del: {
      header: 'Видалення пристрою',
      bodyHead: 'Пристрій',
      bodyTail: 'буде видалено.',
      widgets: 'Крім того, будуть видалені наступні віджети:',
      accept: 'Видалити'
    },
    qr: {
      header: 'Сканування QR-коду',
      init: 'Будь-ласка надайте доступ до камери. ',
      scan: 'Наведіть камеру на QR-код і очікуйте завершення розпізнавання.',
      error: 'Виникла помилка.',
      errors: {
        NotAllowedError: 'Користувач заборонив доступ до камери.',
        NotFoundError: 'Не встановлено відповідний пристрій камери.',
        NotSupportedError:
          'Сторінка не обслуговується через HTTPS (або localhost)',
        NotReadableError: 'Можливо, камера вже використовується',
        OverconstrainedError: 'Ви запросили фронтальну камеру, хоча її немає?',
        StreamApiNotSupportedError: 'Здається, браузеру не вистачає функцій'
      }
    },
    reset: {
      button: 'Скинути',
      header: 'Скидання налаштувань пристрою',
      bodyHead: 'Налаштування пристрою',
      bodyTail: 'буде скинуто до заводських налаштувань.',
      accept: 'Скинути'
    },
    is: {
      shared: 'Цей пристрій надано у спільний доступ'
    },
    state: {
      VIRTUAL: 'Віртуальний пристрій',
      ONLINE: 'Пристрій онлайн',
      OFFLINE: 'Пристрій оффлайн',
      UNKNOWN: 'Незареєстрований пристрій',
      SHARED: 'Зі спільним доступом',
      NODE: 'Тільки локації'
    },
    errorMsg: {
      failAddDev: 'Виникла помилка під час створення нового пристрою!',
      failDelDev: 'Виникла помилка під час видалення пристрою!',
      failUpdDev: 'Виникла помилка під час оновлення пристрою!',
      failStaDev: 'Виникла помилка під час отримання стану пристрою!',
      failAddVirt: 'Виникла помилка під час створення віртуального пристрою!',
      failAddLine: 'Виникла помилка під час додавання нової лінії до пристрою!',
      failDelLine: 'Виникла помилка під час видалення лінії з пристрою!',
      failGetValue: 'Виникла помилка під час отримання значення лінії!',
      failSetValue: 'Виникла помилка під час збереження значення лінії!',
      failLoadTypes: 'Виникла помилка під час отримання списку типів ліній!',
      failGetBrokers: 'Виникла помилка під час отримання списку MQTT брокерів!',
      failGetSetting: 'Виникла помилка під час отримання налаштувань пристрою!',
      failSetSetting: 'Виникла помилка під час збереження налаштувань пристрою!'
    }
  },

  line: {
    table: {
      add: 'Додати лінію',
      history: 'Історія',
      change: 'Змінити',
      widget: 'Віджет',
      rename: 'Псевдонім',
      delete: 'Видалити'
    },
    add: {
      header: 'Додавання нової лінії',
      bodyHead: 'До пристрою',
      bodyTail: ' буде додано нову лінію. Введіть назву, опис та тип.',
      labelName: 'Назва лінії',
      labelDesc: 'Опис лінії',
      labelType: 'Тип лінії',
      accept: 'Додати'
    },
    edit: {
      header: 'Зміна назви лінії',
      bodyHead: 'Введіть нову назву для лінії',
      bodyTail: '.',
      labelName: 'Назва лінії',
      labelDesc: 'Опис лінії',
      accept: 'Змінити'
    },
    value: {
      header: 'Зміна стану лінії',
      bodyHead: 'Введіть новий стан лінії',
      bodyTail: '.',
      label: 'Стан',
      accept: 'Змінити'
    },
    del: {
      header: 'Видалення лінії',
      bodyHead: 'Лінію',
      bodyTail: ' буде видалено.',
      widgets: 'Крім того, будуть видалені наступні віджети:',
      accept: 'Видалити'
    }
  },

  widget: {
    menu: {
      add: 'Додати віджет',
      info: 'Про віджет',
      rename: 'Назвати віджет',
      remove: 'Видалити віджет',
      bundle: 'Додати пачку',
      arrange: 'Розташувати віджети'
    },
    info: {
      header: 'Про віджет',
      goTo: 'До пристрою'
    },
    add: {
      header: 'Додавання нового віджета',
      bodyHead: 'До дашборду буде додано новий віджет на основі лінії',
      bodyTail: '. Введіть назву віджета та виберіть його тип.',
      labelName: 'Назва віджета',
      labelType: 'Тип віджета',
      accept: 'Додати'
    },
    create: {
      header: 'Створення нового віджета',
      body: 'Для створення нового віджета необхідно виконати декілька кроків. Першим кроком є вибір локації. Другий крок - це вибір пристрою. Третій крок - вибір лінії. На останньому кроці введіть назву віджета та виберіть його тип.',
      labelName: 'Назва віджета',
      labelType: 'Тип віджета',
      accept: 'Створити'
    },
    edit: {
      header: 'Зміна назви віджета',
      bodyHead: 'Введіть нову назву для віджета',
      bodyTail: '.',
      labelName: 'Назва віджета',
      accept: 'Змінити'
    },
    del: {
      header: 'Видалення віджета',
      bodyHead: 'Віджет',
      bodyTail: ' буде видалено.',
      accept: 'Видалити'
    },
    list: {
      ShowChart: 'Лінійний графік',
      ShowValue: 'Поточне значення',
      EditValue: 'Зміна значення'
    }
  },

  rule: {
    menu: {},
    errorMsg: {
      failAddRule: 'Виникла помилка під час створеня нового правила!',
      failDelRule: 'Виникла помилка під час видалення правила!',
      failInfRule: 'Виникла помилка під час отримання правила доступу!',
      failLinesIn: 'Не вдалося отримати список вхідних ліній для правила!',
      failLinesOut: 'Не вдалося отримати список вихідних ліній для правила!',
      failLoadTypes: 'Виникла помилка під час завантаження списку типів правил!'
    }
  },

  iface: {
    btn: {
      continue: 'Продовжити',
      reject: 'Скасувати',
      gotIt: 'Ясно',
      clear: 'Очистити',
      save: 'Зберегти',
      back: 'Назад',
      more: 'Далі',
      ok: 'Гаразд'
    },
    tip: {
      owner: 'Ви повинні бути власником, щоб поділитися',
      inDev: 'Тимчасово відключено. Функція в розробці.'
    },
    confirm: {
      irreversible: 'Ця дія незворотня!',
      sure: 'Ви впевнені?'
    },
    search: {
      user: 'Пошук користувача...',
      hint: 'Введіть принаймні 3 символи',
      label: 'Знайти'
    },
    filter: {
      title: 'Фільтр',
      nodes: 'Тільки локації',
      shared: 'Зі спільним доступом'
    },
    label: {
      date: 'Дата',
      time: 'Час',
      confirm: 'Перевірка'
    },
    level: {
      owner: 'Власник',
      admin: 'Адміністратор',
      user: 'Користувач',
      none: 'Без доступу'
    },
    origin: {
      real: 'Реальний',
      virt: 'Віртуальний'
    },
    type: {
      toggle_switch: 'Перемикач',
      rgb_color: 'Колір RGB'
    },
    orgType: {
      home: 'Домашня',
      firm: 'Бізнес'
    },
    prop: {
      org_id: 'ID групи',
      id: 'ID',
      hwid: 'HWID',
      name: 'Назва',
      node: 'Вузол',
      line: 'Лінія',
      type: 'Тип',
      group: 'Група',
      owner: 'Власник',
      device: 'Пристрій',
      widget: 'Віджет',
      parent: 'Предок',
      source: 'Джерело',
      target: 'Призначення',
      location: 'Локація',
      access_level: 'Рівень доступу',

      topic: 'Шлях',
      value: 'Значення',
      unit: 'Од.',
      date: 'Дата',
      time: 'Час',
      level: 'Рівень',
      user: 'Користувач',
      rule: 'Правило',
      error: 'Помилка',
      actions: 'Дії',
      group_pack: 'Власник, Група',

      title: 'Заголовок',
      component: 'Компонент',
      desc: 'Опис',

      device_name: 'Назва пристрою',
      model_name: 'Назва моделі',
      hardware: 'Версія обладнання',
      software: 'Версія програми',
      release: 'Реліз',
      build: 'Білд',
      manufacturer: 'Виробник',
      power: 'Живлення',
      can_listen: 'Може слухати',

      temperature: 'Температура',
      connection: 'З`єднання',
      rssi: 'RSSI',
      quality: 'Якість',
      state: 'Стан',

      keep_alive: 'Тримати активним',
      time_publish: 'Час між сеансами',
      battery_type: 'Тип батареї',
      charging_current: 'Зарядний струм'
    },
    err: {
      error: 'Помилка',
      goHome: 'На головну сторінку',
      require: 'Це поле є обов`язковим',
      space: 'Пропуски не допускаються',
      empty: 'Це поле повинно бути не порожнім',
      alphanum: 'Допускаються лише латинські літери та цифри',
      decimal: 'Допускаються лише десяткові символи',
      hexadec: 'Допускаються лише шістнадцяткові символи',
      minHead: 'Допускається мінімум',
      minTail: 'символів',
      maxHead: 'Допускається максимум',
      maxTail: 'символів',
      minimum: 'Мінімальне значення:',
      maximum: 'Максимальне значення:',
      nothing: 'Тут порожньо',
      earlier: 'Раніше початку',
      itself: 'Не можна переносити вузол у самого себе',
      areSame: 'Джерело і призначення співпадають',
      400: 'Неправильний запит. Запит не може бути виконаний.',
      401: 'Несанкціонований доступ. Потрібна автентифікація.',
      403: 'Заборонено. Сервер відхилив запит.',
      404: 'Цю сторінку не вдалося знайти',
      405: 'Метод запиту не підтримується для запитуваного ресурсу',
      500: 'Внутрішня помилка сервера',
      501: 'Метод не підтримується',
      502: 'Помилка шлюзу',
      503: 'Сервер тимчасово не має можливості обробляти запити'
    }
  },

  err: {
    ACLexists: {
      title: 'Правило доступу вже існує',
      detail: 'Правило доступу для цього користувача вже існує на цьому вузлі'
    },
    Registered: {
      title: 'Вже зареєстровано',
      detail: 'Цей пристрій вже зареєстровано'
    }
  }
};
