/* eslint-disable no-console */
import { getParent, getOrgId, getHWID, isVirt, logger } from '~/utils/helpers';

export const state = () => ({
  currentDev: {
    id: null,
    type: 'device',
    attributes: {
      hwid: null,
      config: null,
      status: null,
      settings: [],
      errors: [],
      lines: [],
      state: '',
      node: '',
      name: ''
    }
  },
  brokers: [],
  settings: []
});

export const getters = {
  getDevice: (s) => s.currentDev,
  getBrokers: (s) => s.brokers,
  getSettings: (s) => s.settings,
  isEnabled: (s) => s.currentDev.attributes.state === 'on',
  isVirtual: (s) => isVirt(s.currentDev.attributes.hwid)
};

export const mutations = {
  setDevice(state, device) {
    if (Object.keys(device).length === 0 && device.constructor === Object) {
      state.currentDev = {
        id: null,
        type: 'device',
        attributes: {
          hwid: null,
          config: null,
          status: null,
          settings: [],
          errors: [],
          lines: [],
          state: '',
          node: '',
          name: ''
        }
      };
    } else {
      state.currentDev = device;
    }
    logger('<[ STORE ]>', 'SET CURR DEV', state.currentDev.id);
  },

  setBrokers(state, list) {
    state.brokers = [...list];
    logger('<[ STORE ]>', 'SET BROKERS', list);
  },

  setSettings(state, settings) {
    state.settings = [...settings];
    logger('<[ STORE ]>', 'SET SETTINGS', state.settings);
  }
};

export const actions = {
  // Create a new device
  async create({ dispatch, commit }, { node, hwid, name, broker }) {
    try {
      let dev = {};
      const orgId = getOrgId(node);
      const options = { name, broker };
      const api = this.$lisaAPI.device;
      const res = await api.create(orgId, hwid, node, options);
      if (res.status === 'ok' && res.data) {
        dev = res.data[0];
        logger('<[ STORE ]>', 'CREATE DEVICE', dev.id);
        await commit('setDevice', dev);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Device',
            errorText: 'device.errorMsg.failAddDev'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('location/loadList', orgId, { root: true });
      await dispatch('location/find', dev ? dev.id : node, { root: true });
    } catch (error) {
      console.error('Store/Device/create', error.message);
    }
  },

  // Update a device
  async update({ dispatch, commit }, { node, name }) {
    try {
      const orgId = getOrgId(node);
      const hwid = getHWID(node);
      const options = { name };
      const api = this.$lisaAPI.device;
      const res = await api.update(orgId, hwid, options);
      if (res.status === 'ok' && res.data) {
        const dev = res.data[0];
        logger('<[ STORE ]>', 'UPDATE DEVICE', dev.id);
        await commit('setDevice', dev);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Device',
            errorText: 'device.errorMsg.failUpdDev'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('location/loadList', orgId, { root: true });
      await dispatch('location/find', node, { root: true });
    } catch (error) {
      console.error('Store/Device/update', error.message);
    }
  },

  // Delete a device
  async delete({ dispatch, commit }, node) {
    try {
      const orgId = getOrgId(node);
      const hwid = getHWID(node);
      const parentId = getParent(node);
      const api = this.$lisaAPI.device;
      const res = await api.delete(orgId, hwid);
      if (res.status === 'ok') {
        logger('<[ STORE ]>', 'DELETE DEVICE', node);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Device',
            errorText: 'device.errorMsg.failDelDev'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('location/loadList', orgId, { root: true });
      await dispatch('location/find', parentId, { root: true });
      await dispatch('acl/list', orgId, { root: true });
    } catch (error) {
      console.error('Store/Device/delete', error.message);
    }
  },

  // Create a new virtual device
  async createVirtual({ dispatch, commit }, { node, name }) {
    try {
      let dev = {};
      const orgId = getOrgId(node);
      const api = this.$lisaAPI.virtual;
      const res = await api.create(orgId, node, name);
      if (res.status === 'ok' && res.data) {
        dev = res.data[0];
        logger('<[ STORE ]>', 'CREATE VIRTUAL', dev.id);
        await commit('setDevice', dev);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Device',
            errorText: 'device.errorMsg.failAddVirt'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('location/loadList', orgId, { root: true });
      await dispatch('location/find', dev ? dev.id : node, { root: true });
    } catch (error) {
      console.error('Store/Device/createVirtual', error.message);
    }
  },

  // Get status of device
  async status({ dispatch, commit }, node) {
    try {
      const orgId = getOrgId(node);
      const hwid = getHWID(node);
      const api = this.$lisaAPI.device;
      const res = await api.status(orgId, hwid);
      if (res.status === 'ok') {
        const dev = res.data[0];
        logger('<[ STORE ]>', 'DEVICE STATUS', dev);
        await commit('setDevice', dev);
        if (dev.attributes.settings.length) {
          await dispatch('loadSettings', node);
        } else {
          await commit('setSettings', []);
        }
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Device',
            errorText: 'device.errorMsg.failStaDev'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Device/status', error.message);
    }
  },

  // Get a list of MQTT brokers.
  async loadBrokers({ dispatch, commit }, orgId) {
    try {
      const api = this.$lisaAPI.device;
      const res = await api.brokers(orgId);
      if (res.status === 'ok') {
        const list = res.data;
        // logger('<[ STORE ]>', 'LOAD BROKERS', list);
        await commit('setBrokers', list);
      } else {
        await commit('setBrokers', []);
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Device',
            errorText: 'device.errorMsg.failGetBrokers'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Device/brokers', error.message);
    }
  },

  // Get device settings
  async loadSettings({ commit, getters }, node) {
    try {
      if (node !== getters.getDevice.id) {
        logger('<[ STORE ]>', '! STRANGE NODE', node);
      }
      if (!getters.getDevice.attributes.settings) {
        logger('<[ STORE ]>', 'SETTINGS', 'ARE MISSING');
        return commit('setSettings', []);
      }
      const orgId = getOrgId(node);
      const hwid = getHWID(node);
      const api = this.$lisaAPI.device;
      const res = await api.loadSettings(orgId, hwid);
      if (res.status === 'ok') {
        const settings = res.data[0].attributes;
        // logger('<[ STORE ]>', 'LOAD SETTINGS', settings);
        await commit('setSettings', settings);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Device',
            errorText: 'device.errorMsg.failGetSetting'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Device/loadSettings', error.message);
    }
  },

  // Set device settings
  async saveSettings({ dispatch, commit }, { node, settings }) {
    try {
      const orgId = getOrgId(node);
      const hwid = getHWID(node);
      const api = this.$lisaAPI.device;
      const res = await api.saveSettings(orgId, hwid, settings);
      if (res.status === 'ok') {
        logger('<[ STORE ]>', 'SAVE SETTINGS', settings);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Device',
            errorText: 'device.errorMsg.failSetSetting'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('loadSettings', node);
    } catch (error) {
      console.error('Store/Device/saveSettings', error.message);
    }
  }
};
