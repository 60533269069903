/* eslint-disable no-console */
import { getParent, getOrgId, getHWID, logger } from '~/utils/helpers';

export const state = () => ({
  currentLine: {
    alias: '',
    topic: '',
    name: '',
    desc: '',
    dir: '',
    type: '',
    unit: '',
    value: '',
    range: false
  },
  live: new Map(),
  history: new Map(),
  // history: {},
  lineTypes: []
});

export const getters = {
  getLine: (s) => s.currentLine,

  isLive: (s) => (key) => s.live.has(key),
  getLive: (s) => (key) => s.live.get(key),
  getAllLive: (s) => Array.from(s.live.values()),
  getAllLiveKeys: (s) => Array.from(s.live.keys()),

  // getHistory: (s) => s.history,
  getHistory: (s) => (key) => s.history.get(key),
  isHistory: (s) => (key) => s.history.has(key),

  getLineTypes: (s) => s.lineTypes,
  getLineName: (s) =>
    s.currentLine.alias ? s.currentLine.alias : s.currentLine.name,
  getLineId: (state, getters, rootState, rootGetters) =>
    rootGetters['device/getDevice'].attributes.hwid +
    '/' +
    state.currentLine.topic,
  getLineById: (state, getters, rootState, rootGetters) => (id) =>
    rootGetters['device/getDevice'].attributes.lines.find(
      (el) => el.topic === id
    )
};

export const mutations = {
  setLine(state, line) {
    if (Object.keys(line).length === 0 && line.constructor === Object) {
      state.currentLine = {
        desc: '',
        dir: '',
        name: '',
        type: '',
        unit: '',
        value: ''
      };
    } else {
      state.currentLine = line;
    }
    logger('<[ STORE ]>', 'CURRENT LINE', state.currentLine.name);
  },

  setLive(state, { key, value }) {
    state.live.set(key, value);
    // logger('<[ STORE ]>', 'SET LIVE', `${key}: ${value}`);
  },
  delLive(state, key) {
    state.live.delete(key);
    logger('<[ STORE ]>', 'DEL LIVE', key);
  },
  clearLive(state) {
    state.live.clear();
    logger('<[ STORE ]>', 'CLEAR LIVE', '!');
  },

  setValue(state, value) {
    state.currentLine.value = value;
    logger('<[ STORE ]>', 'SET VALUE', state.currentLine.value);
  },

  // setHistory(state, history) {
  //   state.history = history;
  //   logger('<[ STORE ]>', 'SET HISTORY', state.history);
  // },
  setHistory(state, { key, data }) {
    state.history.set(key, data);
    // logger('<[ STORE ]>', 'SET HISTORY', key);
  },
  delHistory(state, key) {
    state.history.delete(key);
    logger('<[ STORE ]>', 'DEL HISTORY', key);
  },
  clearHistory(state) {
    state.history.clear();
    logger('<[ STORE ]>', 'CLEAR HISTORY', '!');
  },

  setLineTypes(state, types) {
    state.lineTypes = [...types];
    logger('<[ STORE ]>', 'SET LINE TYPES', state.lineTypes);
  }
};

export const actions = {
  // Load a list of line types for virtual devices
  async loadLineTypes({ commit }) {
    try {
      const api = this.$lisaAPI.virtual;
      const res = await api.getTypes();
      if (res.status === 'ok' && res.data) {
        // logger('<[ STORE ]>', 'LOAD LINE TYPES', res.data);
        await commit('setLineTypes', res.data);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Line',
            errorText: 'device.errorMsg.failLoadTypes'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Line/loadLineTypes', error.message);
    }
  },

  // Append a line to a virtual device
  async appendLine({ dispatch, commit }, { node, type, name, desc }) {
    try {
      let dev = {};
      const orgId = getOrgId(node);
      const hwid = getHWID(node);
      const api = this.$lisaAPI.virtual;
      const res = await api.addLine(orgId, hwid, type, name, desc);
      if (res.status === 'ok' && res.data) {
        dev = res.data[0];
        logger('<[ STORE ]>', 'APPEND LINE', dev.id);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Line',
            errorText: 'device.errorMsg.failAddLine'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('device/status', node, { root: true });
    } catch (error) {
      console.error('Store/Line/appendLine', error.message);
    }
  },

  // Remove a line from a virtual device
  async removeLine({ dispatch, commit }, path) {
    try {
      const orgId = getOrgId(path);
      const devId = getParent(path);
      const api = this.$lisaAPI.virtual;
      const res = await api.delLine(orgId, path);
      if (res.status === 'ok') {
        logger('<[ STORE ]>', 'REMOVE LINE', path);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Line',
            errorText: 'device.errorMsg.failDelLine'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('device/status', devId, { root: true });
    } catch (error) {
      console.error('Store/Line/removeLine', error.message);
    }
  },

  // Rename a line's alias
  async aliasLine(
    { dispatch, commit, getters, rootGetters },
    { node, topic, alias }
  ) {
    try {
      const orgId = getOrgId(node);
      const hwid = getHWID(node);
      const name = rootGetters['device/getDevice'].attributes.name;
      const options = { name, topic, alias };
      const api = this.$lisaAPI.device;
      const res = await api.update(orgId, hwid, options);
      if (res.status === 'ok') {
        logger('<[ STORE ]>', 'RENAME LINE', getters.getLineId);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Line',
            errorText: 'device.errorMsg.failUpdDev'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('device/status', node, { root: true });
      await commit('setLine', getters.getLineById(topic));
    } catch (error) {
      console.error('Store/Line/aliasLine', error.message);
    }
  },

  // Get journal of device line’s real textual data
  async textualHistory({ commit }, { node, line, from, to }) {
    try {
      const orgId = getOrgId(node);
      const lineId = `${node}/${line}`;
      const limit = null;
      const offset = null;
      const options = { from, to, limit, offset };
      const api = this.$lisaAPI.device;
      const res = await api.linedata(orgId, lineId, options);
      if (res.status === 'ok') {
        const history = res.data[0].attributes;
        logger('<[ STORE ]>', 'LINE HISTORY', lineId);
        await commit('setHistory', { key: lineId, data: history });
      } else {
        await commit('setHistory', { key: lineId, data: {} });
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Line',
            errorText: 'device.errorMsg.failHisDev'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Line/textualHistory', error.message);
    }
  },

  // Get journal of device line’s real digital data
  async numericHistory({ commit }, { node, line, from, to }) {
    try {
      const orgId = getOrgId(node);
      const lineId = `${node}/${line}`;
      const api = this.$lisaAPI.device;
      const res = await api.linejournal(orgId, lineId, from, to);
      if (res.status === 'ok') {
        const history = res.data[0].attributes;
        logger('<[ STORE ]>', 'LINE HISTORY', lineId);
        await commit('setHistory', { key: lineId, data: history });
      } else {
        await commit('setHistory', { key: lineId, data: {} });
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Line',
            errorText: 'device.errorMsg.failHisDev'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Line/numericHistory', error.message);
    }
  },

  // Get journal of device line’s adapted data
  async adaptedHistory({ commit }, { node, line, from, to, count }) {
    try {
      const orgId = getOrgId(node);
      const lineId = `${node}/${line}`;
      const options = { from, to, count };
      const api = this.$lisaAPI.device;
      const res = await api.rangejournal(orgId, lineId, options);
      if (res.status === 'ok') {
        const history = res.data[0].attributes;
        logger('<[ STORE ]>', 'LINE HISTORY', lineId);
        await commit('setHistory', { key: lineId, data: history });
      } else {
        await commit('setHistory', { key: lineId, data: {} });
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Line',
            errorText: 'device.errorMsg.failHisDev'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Line/adaptedHistory', error.message);
    }
  },

  // Get the current value of a line
  async loadLine({ commit }, path) {
    try {
      const orgId = getOrgId(path);
      const paths = [path];
      // const topic = getHWID(path);
      const api = this.$lisaAPI.device;
      const res = await api.getLine(orgId, paths);
      if (res.status === 'ok' && res.data) {
        const value = res.data[0].attributes.value;
        // logger('<[ STORE ]>', 'LOAD LINE', `${path}: ${value}`);
        await commit('setLive', { key: path, value });
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Line',
            errorText: 'device.errorMsg.failGetValue'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/Line/loadLine', error.message);
    }
  },

  // Set the current value of a line
  async saveLine({ dispatch, commit, getters }, { path, value }) {
    try {
      const orgId = getOrgId(path);
      const node = getParent(path);
      const api = this.$lisaAPI.device;
      const res = await api.setLine(orgId, path, value);
      if (res.status === 'ok') {
        logger('<[ STORE ]>', 'SAVE LINE', `${path}: ${value}`);
        if (getters.isLive(path)) {
          await commit('setLive', { key: path, value });
        }
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/Line',
            errorText: 'device.errorMsg.failSetValue'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      // Сервер не оновлює змінену лінію одразу, отже мусимо оновлювати через секунду
      setTimeout(() => dispatch('device/status', node, { root: true }), 1000);
    } catch (error) {
      console.error('Store/Line/saveLine', error.message);
    }
  }
};
