// eslint-disable-next-line no-unused-vars
// import framesDataJSON from '../assets/01010003350002801.json';
// import framesDataJSON from '../assets/22.json';
// import framesDataJSON from '../assets/01010003470005401_fast_mot_yolov4.json';
// import framesDataJSON2 from '../assets/01010003470005401_crowd_det.json';
// import { getErrorResponse } from '~/utils/apiUtils';

export default ($axios) => ({
  // Link and name of video
  getVideo() {
    // return '22_with_tracks.mp4';
    return '01010003470005401_converted2.mp4';
  },
  // JSON data of each video's frame
  getframesData() {
    // return framesDataJSON;
  },
  getframesData2() {
    // return framesDataJSON2;
  }
});
