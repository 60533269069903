// eslint-disable-next-line no-unused-vars
import { unWrap, getErrorResponse } from '~/utils/apiUtils';

// https://kp-staging.lanet.house/public/#api-Auth
export default ($axios) => ({
  // https://kp-staging.lanet.house/public/#api-Auth-GetAuthProvider
  // Go to auth provider page — /v1/auth/:provider
  login() {
    window.location.href = '/v1/auth/google';
  },

  // https://kp-staging.lanet.house/public/#api-Auth-GetAuthProviderCallback
  // Go to auth callback — /v1/auth/:provider/callback
  callBack() {
    // window.location.href = '/v1/auth/google';
  },

  // https://kp-staging.lanet.house/public/#api-Auth-GetAuthProviderLogout
  // Close the session — /v1/auth/:provider/logout
  async logout(params) {
    try {
      // params: { include: 'isowner' }

      const { status, data, errors } = await $axios.$get(
        '/v1/auth/google/logout'
      );
      return {
        status: status || 'ok',
        data: data || {},
        errors
      };
    } catch (e) {
      return getErrorResponse(e);
    }
  }
});
