
import { getIcon, getColor, getGist } from '~/utils/helpers';
export default {
  name: 'NodeIcon',

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    tip: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  },

  computed: {
    isTip() {
      return this.tip && this.item?.type === 'device';
    },

    status() {
      return getGist(this.item);
    },

    tooltip() {
      return this.$te(`device.state.${this.status}`)
        ? this.$t(`device.state.${this.status}`)
        : this.status;
    }
  },

  methods: {
    getIcon() {
      return getIcon(this.item);
    },

    getColor() {
      return getColor(this.status);
    }
  }
};
