
export default {
  name: 'Pack',

  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
};
