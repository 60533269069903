/* eslint-disable no-console */
import { getOrgId, logger } from '~/utils/helpers';

export const state = () => ({
  currentRule: {},
  rules: [],
  scope: []
});

export const getters = {
  getRules: (s) => s.rules,
  getScope: (s) => s.scope,
  getRule: (s) => s.currentRule,
  getRuleById: (s) => (id) => s.rules.find((el) => el.id === id),
  getRulesByNode: (s) => (node) =>
    s.rules.filter((el) => el.attributes.node === node),
  isNodeShared: (s, getters) => (node) => {
    const r = getters.getRulesByNode(node);
    const isOwner = r.length === 1 && r[0].attributes.level === 'owner';
    return !!r.length && !isOwner;
  }
};

export const mutations = {
  setRules(state, rules) {
    state.rules = [...rules];
    logger('<[ STORE ]>', 'SET ACL RULES', state.rules);
  },

  setScope(state, scope) {
    state.scope = [...scope];
    logger('<[ STORE ]>', 'SET ACL SCOPE', state.scope);
  },

  setRule(state, rule) {
    if (Object.keys(rule).length === 0 && rule.constructor === Object) {
      state.currentRule = {
        id: '',
        type: 'acl',
        attributes: {
          created_at: '',
          level: '',
          node: '',
          org_id: 0,
          user_id: 0
        },
        user: {
          avatar: '',
          email: '',
          firstname: '',
          lastname: '',
          nickname: ''
        }
      };
    } else {
      state.currentRule = rule;
    }
    logger('<[ STORE ]>', 'CURR ACL RULE', state.currentRule.id);
  }
};

export const actions = {
  // Create an access rule
  async create({ dispatch, commit }, { node, user, level }) {
    try {
      const orgId = getOrgId(node);
      const res = await this.$lisaAPI.acl.create(orgId, +user, node, level);
      if (res.status === 'ok') {
        if (res.data) {
          const rules = res.data;
          logger('<[ STORE ]>', 'ADD ACL RULE', rules);
        }
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/ACL',
            errorText: 'acl.errorMsg.failAddACL'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('list', orgId);
      await dispatch('filter', node);
    } catch (error) {
      console.error('Store/ACL/create', error.message);
    }
  },

  // Delete an access rule
  async delete({ dispatch, commit }, { node, aclId }) {
    try {
      const orgId = getOrgId(node);
      const res = await this.$lisaAPI.acl.delete(orgId, aclId);
      if (res.status === 'ok') {
        logger('<[ STORE ]>', 'DEL ACL RULE', aclId);
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/ACL',
            errorText: 'acl.errorMsg.failDelACL'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('list', orgId);
      await dispatch('filter', node);
    } catch (error) {
      console.error('Store/ACL/delete', error.message);
    }
  },

  // Get the list of access rules
  async list({ dispatch, commit }, node) {
    try {
      const orgId = getOrgId(node);
      const include = 'userinfo,children';
      const res = await this.$lisaAPI.acl.list(orgId, { node, include });
      if (res.status === 'ok') {
        if (res.meta.count > 0) {
          const rules = res.data;
          const users = res.included;
          rules.forEach((el) => {
            const user = users.find((u) => +u.id === +el.attributes.user_id);
            el.user = user.attributes;
          });
          // logger('<[ STORE ]>', 'LOAD ACL LIST', rules);
          await commit('setRules', rules);
        } else {
          // logger('<[ STORE ]>', 'LOAD ACL LIST', '[]');
          await commit('setRules', []);
        }
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store/ACL',
            errorText: 'acl.errorMsg.failLstACL'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      await dispatch('filter', node);
    } catch (error) {
      console.error('Store/ACL/list', error.message);
    }
  },

  // Filter access rules by node id in the list of access rules
  async filter({ commit, getters }, nodeId) {
    if (!getters.getRules || !getters.getRules.length)
      await commit('setScope', []);

    const scope = getters.getRulesByNode(nodeId);
    if (scope.length) {
      // logger('<[ STORE ]>', `#${nodeId}`, scope.length);
      await commit('setScope', scope);
    } else {
      // logger('<[ STORE ]>', 'ACL FILTER', 'FOUND NOTHING');
      await commit('setScope', []);
    }
  }
};
