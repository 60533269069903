/* eslint-disable no-console */
import { logger } from '~/utils/helpers';

export const state = () => ({
  widgets: [],
  usedLines: [],
  layouts: {},
  breakpoints: [
    { name: 'xxs', length: 0, columns: 6, defaultWidth: 6 },
    { name: 'xs', length: 480, columns: 12, defaultWidth: 6 },
    { name: 'sm', length: 768, columns: 18, defaultWidth: 9 },
    { name: 'md', length: 996, columns: 27, defaultWidth: 9 },
    { name: 'lg', length: 1400, columns: 36, defaultWidth: 9 }
  ]
});

export const getters = {
  getWidgets: (s) => s.widgets,
  getLayouts: (s) => s.layouts,
  getBreakpoints: (s) => s.breakpoints,
  getIndexById: (s) => (id) => s.widgets.findIndex((el) => el.id === id),
  getWidgetById: (s) => (id) => s.widgets.find((el) => el.id === id),
  getUsedLines: (s) => s.usedLines,
  isLineUsed: (s) => (id) => s.usedLines.some((el) => el.topic === id)
};

export const mutations = {
  setWidgets(state, widgets) {
    state.widgets = [...widgets];
    logger('<[ STORE ]>', 'SET WIDGETS', state.widgets);

    const usedLines = [];
    function processWidgets(widgets) {
      for (const w of widgets) {
        if (w?.props?.topic)
          usedLines.push({
            id: w.id,
            title: w.title,
            topic: w.props.topic
          });
        if (w?.props?.items && Array.isArray(w.props.items))
          processWidgets(w.props.items);
      }
    }

    processWidgets(widgets);
    state.usedLines = usedLines;
  },

  setLayouts(state, layouts) {
    state.layouts = { ...layouts };
    logger('<[ STORE ]>', 'SET LAYOUTS', state.widgets);
  },

  removeWidget(state, index) {
    state.widgets.splice(index, 1);
  }
};

export const actions = {
  // Create a widget
  async create({ dispatch, commit, getters }, widget) {
    if (
      !Object.prototype.hasOwnProperty.call(widget, 'component') ||
      !Object.prototype.hasOwnProperty.call(widget, 'props') ||
      !Object.prototype.hasOwnProperty.call(widget, 'title')
    ) {
      console.error('Store/Dashboard/addWidget', 'Wrong property');
      return;
    }
    const widgets = [...getters.getWidgets, widget];
    logger('<[ STORE ]>', 'CREATE WIDGET', widget.id);
    await dispatch('saveWidgets', widgets);
  },

  // Update a widget
  async update({ dispatch, commit, getters }, { id, name }) {
    const widgets = [...getters.getWidgets];
    const index = getters.getIndexById(id);
    if (index < 0) return;
    let widget = widgets[index];
    widget = { ...widget, title: name };
    widgets[index] = widget;
    logger('<[ STORE ]>', 'UPDATE WIDGET', widget.id);
    await commit('setWidgets', widgets);
    await dispatch('saveWidgets', widgets);
  },

  // Delete a widget
  async delete({ dispatch, commit, getters }, id) {
    const index = getters.getIndexById(id);
    if (index < 0) return;
    await commit('removeWidget', index);
    logger('<[ STORE ]>', 'DELETE WIDGET', id);
    await dispatch('saveWidgets', getters.getWidgets);
  },

  // Load a list of widgets
  async loadWidgets({ dispatch, commit, getters, rootGetters }) {
    const user = rootGetters.getIdentity;
    const org = rootGetters['organization/getCurrent'];
    if (!user || !user.ID || !org || !org.id) return;
    const dashboardName = `${user.ID}.${org.id}.dash`;
    const dash = localStorage.getItem(dashboardName);
    try {
      const parsed = JSON.parse(dash);
      if (!parsed) throw new Error('Missed or invalid JSON');
      // logger('<[ STORE ]>', 'LOAD WIDGETS', parsed);
      await commit('setWidgets', parsed);
      await dispatch('loadLayouts', parsed);
      await dispatch('reviveWidgets', parsed);
    } catch (e) {
      localStorage.removeItem(dashboardName);
      await commit('setWidgets', []);
      logger('<[ STORE ]>', 'LOAD DASH', 'EMPTY');
    }
  },

  // Save a list of widgets
  saveWidgets({ getters, dispatch, rootGetters }, widgets) {
    const user = rootGetters.getIdentity;
    const org = rootGetters['organization/getCurrent'];
    if (!user || !user.ID || !org || !org.id || !widgets) return;
    const dashboardName = `${user.ID}.${org.id}.dash`;
    const stringified = JSON.stringify(widgets);
    localStorage.setItem(dashboardName, stringified);
    logger('<[ STORE ]>', 'SAVE WIDGETS', dashboardName);
    dispatch('loadWidgets');
  },

  // Load layouts from widgets
  loadLayouts({ commit, getters }, widgets) {
    const layouts = {};
    widgets.forEach((widget) => {
      for (const key in widget.layout) {
        if (!layouts[key]) layouts[key] = [];
        const widgetIndex = layouts[key].findIndex(
          (w) => w.i === widget.layout[key].i
        );
        if (widgetIndex === -1) layouts[key].push(widget.layout[key]);
        else layouts[key][widgetIndex] = widget.layout[key];
      }
    });
    // logger('<[ STORE ]>', 'LOAD LAYOUTS', layouts);
    commit('setLayouts', layouts);
  },

  // Save layouts to widgets
  saveLayouts({ getters, dispatch, rootGetters }, layouts) {
    const widgets = [...getters.getWidgets];
    const newWidgets = widgets.map((w, idx) => {
      const layout = {};
      for (const key in layouts) layout[key] = layouts[key][idx];
      return { ...w, layout };
    });
    // console.log('Save Updated Widgets:', newWidgets);
    dispatch('saveWidgets', newWidgets);
  },

  // Make widgets come alive
  reviveWidgets({ commit, getters }, widgets) {
    widgets.forEach((w) => {
      if (w.props?.topic) {
        if (w.component === 'ShowValue') {
          // logger('<[ STORE ]>', 'LOAD ShowValue', w.props.topic);
          commit(
            'line/setLive',
            { key: w.props.topic, value: '' },
            { root: true }
          );
        } else if (w.component === 'ShowChart') {
          // logger('<[ STORE ]>', 'LOAD ShowChart', w.props.topic);
          // commit(
          //   'line/setHistory',
          //   { key: w.props.topic, value: { data: [], debug: {} } },
          //   { root: true }
          // );
        } else {
          // logger('<[ STORE ]>', 'LOAD Otherwise', w.props.topic);
        }
      }
    });
  }
};
