import { getErrorResponse } from '~/utils/apiUtils';

// https://kp-staging.lanet.house/public/#api-VirtualDevice
// Virtual Device
export default ($axios) => ({
  // https://kp-staging.lanet.house/public/#api-VirtualDevice-PostRegisterVirtualDevice
  // Create a new virtual device
  async create(orgId, node, name) {
    try {
      const { status, data, errors } = await $axios.$post(
        `/v1/org/${orgId}/virtualdevice`,
        {
          node,
          name
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-VirtualDevice-GetVirtualDevicesLinesTypes
  // Get a list of line types for virtual devices
  async getTypes() {
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/virtualdevice/lines`
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-VirtualDevice-PostVirtualLine
  // Add a line to a virtual device
  async addLine(orgId, hwid, type, name, desc) {
    try {
      const { status, data, errors } = await $axios.$post(
        `/v1/org/${orgId}/virtualdevice/line`,
        {
          hwid,
          type,
          name,
          desc
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-VirtualDevice-DelVirtualDeviceLine
  // Delete a line from a virtual device
  async delLine(orgId, topic) {
    try {
      const { status, data, errors } = await $axios.$delete(
        `/v1/org/${orgId}/virtualdevice/line`,
        {
          params: { topic }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  }
});
