
import { getColor, getMDI } from '~/utils/helpers';

export default {
  name: 'NodeTreeFilter',

  props: {
    filters: {
      type: Object,
      required: true
    },
    isFilter: Boolean
  },

  data() {
    return {
      isMenu: false
    };
  },

  methods: {
    getMDI(value) {
      return getMDI(value);
    },
    getColor(value) {
      return getColor(value);
    },

    isFirst(key) {
      return Object.keys(this.filters)[0] === key;
    },

    clearFilter() {
      Object.values(this.filters).forEach((f) => (f.value = false));
    }
  }
};
