/* eslint-disable no-console */
import { logger } from '~/utils/helpers';

export const state = () => ({
  // identity — an identified and authorized person
  identity: {
    ID: null,
    is_root: false,
    CreatedAt: '',
    DeletedAt: null,
    UpdatedAt: '',
    avatar_url: '',
    email: '',
    first_name: '',
    last_name: '',
    nick_name: ''
  },
  token: null,
  locales: ['en', 'uk', 'pl'],
  locale: 'uk',
  error: {
    module: null,
    errorText: null,
    code: null,
    title: null,
    detail: null
  }
});

export const mutations = {
  setIdentity(state, identity) {
    if (Object.keys(identity).length === 0 && identity.constructor === Object) {
      state.identity = {
        ID: null,
        is_root: false,
        CreatedAt: '',
        DeletedAt: null,
        UpdatedAt: '',
        avatar_url: '',
        email: '',
        first_name: '',
        last_name: '',
        nick_name: ''
      };
    } else {
      state.identity = identity;
    }
    logger('<[ STORE ]>', 'SET IDENTITY', identity);
  },

  setToken(state, token) {
    state.token = token;
  },
  clearToken(state) {
    state.token = null;
  },

  setError(state, error) {
    state.error = error;
  },
  clearError(state) {
    state.error.module = null;
    state.error.errorText = null;
    state.error.code = null;
    state.error.title = null;
    state.error.detail = null;
  }
};

export const getters = {
  getIdentity: (s) => s.identity,
  hasToken: (s) => !!s.token,
  error: (s) => s.error
};

export const actions = {
  login({ $config }, identity) {
    // Redirect to auth page by google auth
    this.$lisaAPI.auth.login();
  },

  // Load Identity Info
  async loadIdentityInfo({ commit }) {
    try {
      const api = this.$lisaAPI.user;
      const res = await api.me();
      if (res.status === 'ok') {
        // logger('<[ STORE ]>', 'LOAD IDENTITY', res.data);
        await commit('setIdentity', res.data);
      } else {
        await commit('setIdentity', {});
        commit(
          'setError',
          {
            data: res,
            module: 'Store',
            errorText: 'auth.errorMsg.failLoadIdentityInfo'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
    } catch (error) {
      console.error('Store/loadIdentityInfo', error.message);
    }
  },

  // Logout and redirect
  async logout({ commit }) {
    try {
      const api = this.$lisaAPI.auth;
      const res = await api.logout();
      if (res.status === 'ok') {
        await commit('clearToken');
        await commit('setIdentity', {});
      } else {
        commit(
          'setError',
          {
            data: res,
            module: 'Store',
            errorText: 'auth.errorMsg.failLogout'
          },
          { root: true }
        );
        throw new Error(res.detail);
      }
      this.$router.push(this.app.localePath('auth'));
    } catch (error) {
      console.error('Store/logout', error.message);
    }
  }
};
