import { getErrorResponse } from '~/utils/apiUtils';

// https://kp-staging.lanet.house/public/#api-DeviceBack
export default ($axios) => ({
  // https://kp-staging.lanet.house/public/#api-DeviceBack-PostRegisterDevice
  // Create a new device
  async create(orgId, hwid, node, options = {}) {
    const { name, broker } = options;
    try {
      const { status, data, errors } = await $axios.$post(
        `/v1/org/${orgId}/device`,
        {
          hwid,
          node,
          name,
          broker
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-DeviceBack-UpdateDevice
  // Update a device
  async update(orgId, hwid, options = {}) {
    const { name, topic, alias } = options;
    try {
      const { status, data, errors } = await $axios.$patch(
        `/v1/org/${orgId}/device`,
        {
          hwid,
          name,
          lines: [
            {
              topic,
              alias
            }
          ]
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-DeviceBack-DelDeviceHWID
  // Delete a device
  async delete(orgId, hwid) {
    try {
      const { status, data, errors } = await $axios.$delete(
        `/v1/org/${orgId}/device`,
        {
          params: {
            hwid
          }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Point-GetOrgDeviceState
  // Get status of device
  async status(orgId, hwid) {
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/device`,
        {
          params: {
            device: hwid
          }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-DeviceBack-GetDeviceBrokers
  // Get a list of MQTT brokers.
  async brokers(orgId) {
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/device_brokers`,
        {
          params: {}
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Point-GetOrgLineJrnl
  // Get journal of device line’s real digital data
  async linejournal(orgId, line, from, to) {
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/linejournal`,
        {
          params: {
            line,
            from,
            to
          }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Point-GetOrgLineData
  // Get journal of device line’s real textual data
  async linedata(orgId, line, options = {}) {
    const { from, to, limit, offset } = options;
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/linedata`,
        {
          params: {
            line,
            from,
            to,
            limit,
            offset
          }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Point-GetOrgLineRangeData
  // Get journal of device line’s adapted data
  async rangejournal(orgId, line, options = {}) {
    const { from, to, time, step, count } = options;
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/rangejournal`,
        {
          params: {
            line,
            from,
            to,
            time,
            step,
            count
          }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-DeviceBack-GetDeviceLineData
  // Get the current status of a line
  async getLine(orgId, paths) {
    const queryParams = paths
      .map((item) => `topic=${encodeURIComponent(item)}`)
      .join('&');

    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/device/line?${queryParams}`
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-DeviceBack-PatchDeviceLineData
  // Set the current status of a line
  async setLine(orgId, path, value) {
    try {
      const { status, data, errors } = await $axios.$patch(
        `/v1/org/${orgId}/device/line`,
        {
          topic: path,
          value
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Point-GetDeviceSettings
  // Load device settings
  async loadSettings(orgId, hwid) {
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/devicesettings`,
        {
          params: {
            device: hwid
          }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Point-PatchDeviceSettings
  // Save device settings
  async saveSettings(orgId, hwid, settings) {
    try {
      const { status, data, errors } = await $axios.$patch(
        `/v1/org/${orgId}/devicesettings`,
        {
          device: hwid,
          settings
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  }
});
