import colors from 'vuetify/es5/util/colors';

export default function ({ app }) {
  return {
    customVariables: ['~/assets/variables.scss'],
    theme: {
      dark: false,
      options: {
        customProperties: true
      },
      themes: {
        light: {
          primary: colors.blue.base,
          secondary: colors.grey.darken1,
          accent: colors.shades.black,
          'header-dashboard': colors.blue.darken2,
          'header-device': '#166f6f',
          'header-node': '#5533ff',
          'device-online': colors.green.darken2,
          'device-virtual': colors.cyan.darken3,
          'device-unknown': '#bdbdbd',
          info: colors.teal.lighten1,
          success: colors.green.accent3,
          warning: colors.amber.base,
          error: colors.red.accent4,
          'error-back': '#AA0000'
        },
        dark: {
          primary: colors.blue.lighten1,
          secondary: colors.amber.darken3,
          accent: colors.grey.darken3,
          'header-dashboard': '#153060',
          'header-device': '#113333',
          'header-node': '#221466',
          'device-online': colors.green.accent3,
          'device-virtual': colors.cyan.accent2,
          'device-unknown': '#707070',
          info: colors.teal.lighten1,
          success: colors.green.accent3,
          warning: colors.amber.base,
          error: colors.red.darken1,
          'error-back': '#770000'
        }
      }
    },
    lang: {
      t: (key, ...params) => app.i18n.t(key, params)
    }
  };
}
