
/* eslint-disable no-console */
import { mapGetters, mapActions } from 'vuex';
// import { logger } from '~/utils/helpers';

export default {
  name: 'Default',

  data() {
    return {
      drawer: true,
      miniVariant: false
    };
  },

  computed: {
    ...mapGetters({
      user: 'getIdentity'
    }),

    drawerWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 264;
        case 'sm':
          return 320;
        case 'md':
          return 360;
        case 'lg':
          return 400;
        case 'xl':
          return 480;
        default:
          return 400;
      }
    }
  },

  watch: {
    user() {
      this.loadPageSettins();
    },
    drawer() {
      this.savePageSettins();
    },
    miniVariant() {
      this.savePageSettins();
    },
    '$vuetify.theme.dark'(newLocale, oldLocale) {
      this.savePageSettins();
    },

    drawerWidth() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.miniVariant = false;
      }
    }
  },

  created() {
    console.log('# # # # # #   LAYOUT CREATED   # # # # # #');
  },

  mounted() {
    console.log('# # # # # #   LAYOUT MOUNTED   # # # # # #');
    this.$moment.locale(this.$i18n.locale);
    this.loadLineTypes();
  },

  methods: {
    ...mapActions({
      loadLineTypes: 'line/loadLineTypes'
    }),

    // Збереження параметрів бічного меню в localStorage
    savePageSettins() {
      if (!this.user) return;
      const settingName = `${this.user.ID}.drawer`; // this.$route.name ???
      const dark = this.$vuetify.theme.dark;
      const settingData = [this.drawer, this.miniVariant, dark];
      const stringified = JSON.stringify(settingData);
      localStorage.setItem(settingName, stringified);
      // logger('>>> >>> >>>', 'SAVE DRAWER', stringified);
    },
    // Завантаження параметрів бічного меню з localStorage
    loadPageSettins() {
      if (!this.user) return;
      const settingName = `${this.user.ID}.drawer`; // this.$route.name ???
      if (localStorage.getItem(settingName)) {
        try {
          let dark = false;
          const parsed = JSON.parse(localStorage.getItem(settingName));
          [this.drawer, this.miniVariant, dark] = parsed;
          this.$vuetify.theme.dark = dark;
          // logger('<<< <<< <<<', 'LOAD DRAWER', parsed);
        } catch (e) {
          localStorage.removeItem(settingName);
        }
      }
    }
  }
};
