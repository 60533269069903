/* eslint-disable no-console */

export function prepareError(
  module = '',
  errorText = '',
  code = '',
  title = '',
  detail = ''
) {
  return {
    module,
    errorText,
    code,
    title,
    detail
  };
}

// Convert hex color to rgba
export const HexToRGBA = (color, opacity) => {
  if (typeof color !== 'string') return color;
  color = color.replace('#', '');
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
};

// Filter array of objects by string
export const filterArray = (arr, str) => {
  if (!str) return arr;
  const search = str.toLowerCase();
  return arr.filter((obj) => filter(obj, search));
};
const filter = (obj, search) => {
  if (typeof obj === 'object') {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (typeof value === 'object') {
          if (filter(value, search)) {
            return true;
          }
        } else if (
          typeof value === 'string' &&
          value.toLowerCase().includes(search)
        ) {
          return true;
        }
      }
    }
  }
  return false;
};

// Find object in nested array using recursion
export const findItemNested = (arr, itemId, nestingKey) =>
  arr.reduce((a, item) => {
    // if (item.id === itemId) logger('<Find Item>', 'FOUND', item);

    if (a) return a;
    if (item.id === itemId) return item;
    if (item[nestingKey])
      return findItemNested(item[nestingKey], itemId, nestingKey);
    logger('<Find Item>', 'FOUND', 'NOTHING');
    return undefined;
  }, null);

// Find org ID by node ID
export const getOrgId = (id) => {
  // logger('<Get OrgID>', 'ID', id);
  if (id) return id.split('/', 1).toString();
};

// Find parent of node by ID
export const getParent = (id) => {
  if (typeof id !== 'string') return id;
  const slash = id.lastIndexOf('/');
  const parent = slash === -1 ? '' : id.slice(0, slash);
  return parent;
};

// Find HWID by node ID
export const getHWID = (id) => {
  // logger('<Get  HWID>', 'ID', id);
  if (typeof id !== 'string') return id;
  const slash = id.lastIndexOf('/');
  const hwid = slash === -1 ? '' : id.slice(slash + 1);
  return hwid;
};

// Is the device virtual?
export const isVirt = (hwid) => {
  if (typeof hwid !== 'string') return false;
  return hwid.length === 16 && hwid[0] === 'V';
};

// Is the node root?
export const isRoot = (id) => {
  return id === getOrgId(id);
};

// Return gist (essence) of node
export const getGist = (node) => {
  if (node.type === 'node') return 'NODE';
  else if (node.type === 'device') {
    if (node.state === 1)
      return isVirt(getHWID(node.id)) ? 'VIRTUAL' : 'ONLINE';
    else return node.state === -1 ? 'UNKNOWN' : 'OFFLINE';
  }
};

// Return icon color by type
export const getColor = (type) => {
  const colorMap = {
    NODE: '',
    SHARED: '',
    ONLINE: 'device-online',
    VIRTUAL: 'device-virtual',
    OFFLINE: '',
    UNKNOWN: 'device-unknown'
  };
  return colorMap[type] || '';
};

// Return MDI icon by type
export const getMDI = (type) => {
  const mdiMap = {
    org: 'mdi-home-city-outline', // 'mdi-factory', 'mdi-domain',
    loc: 'mdi-map-marker',
    cnt: 'mdi-router-network',
    sns: 'mdi-motion-sensor',
    usr: 'mdi-account',
    rul: 'mdi-script-outline',
    map: 'mdi-map',
    plan: 'mdi-floor-plan',
    node: 'mdi-map-marker',
    device: 'mdi-switch', // 'mdi-router-network',
    empty: 'mdi-diameter-variant',
    info: 'mdi-information-outline',
    help: 'mdi-help-circle', // mdi-help-circle-outline
    alert: 'mdi-alert-circle-outline', // mdi-alert-circle
    NODE: 'mdi-map-marker',
    DEVICE: 'mdi-network', // for "All Devices" option
    SHARED: 'mdi-account-multiple',
    ONLINE: 'mdi-check-network',
    VIRTUAL: 'mdi-check-network-outline',
    OFFLINE: 'mdi-minus-network-outline', // 'mdi-close-network', 'mdi-network-off',
    UNKNOWN: 'mdi-help-network'
    // mdi-plus-network,			// Add Device
    // mdi-minus-network			// Remove Device
  };
  return mdiMap[type] || mdiMap.alert;
};

// Return icon for node
export const getIcon = (el) => {
  if (isRoot(el.id)) return getMDI('org');

  const type = getGist(el) || el.type;
  return getMDI(type);
};

// Transform the first letter of string to uppercase
export const capitalizeFirstLetter = (
  [first, ...rest],
  locale = navigator.language
) => {
  return first === undefined
    ? ''
    : first.toLocaleUpperCase(locale) + rest.join('');
};

// Remove forbidden characters
export const prepareName = (value) => {
  if (typeof value !== 'string') return value;
  const forbidden = /[^a-zA-Z0-9_$]/g;
  return value.replace(forbidden, '');
};

// Replace underscore character to space and capitalize string
export const prepareString = (value) => {
  if (typeof value !== 'string') return value;
  return capitalizeFirstLetter(value.replace(/_/g, ' '));
};

// Parse string to object
export const parseString = (str) => {
  if (typeof str !== 'string') return str;
  let obj = {};
  str.split(',').forEach((el) => {
    obj = { ...obj, ...Object.fromEntries([el.trim().split('=')]) };
  });
  return obj;
};

// Inserts a space after a comma and colon, removes quotation marks and parentheses
export const formatString = (value) => {
  if (typeof value !== 'string') return value;
  return value
    .replace(/([,:])/g, '$1 ')
    .replace(/\\"/g, '`')
    .replace(/[{"}]/g, '')
    .replace(/`/g, '"');
};

// Transform JSON to string and call formatString
export const formatJSON = (value) => {
  if (typeof value !== 'object') return value;
  return formatString(JSON.stringify(value));
};

// Crop string and fill spaces to fix length
export const alignStr = (str, len, dir = 'R') => {
  let string = str.slice(0, len);
  if (dir === 'R') string = string.padStart(len);
  else string = string.padEnd(len);
  return string;
};

// Formatted console log
export const logger = (prefix, prop, value) => {
  const lenPrefix = 11;
  const lenProp = 14;
  const length = 67;
  const string =
    alignStr(prefix, lenPrefix, 'L') +
    '   ' +
    alignStr(prop, lenProp, 'R') +
    '   ' +
    formatJSON(value);
  console.log(string.slice(0, length) + (string.length > length ? '...' : ''));
};

// Add type to object
export const addType = (type) => (item) => {
  return { ...item, type };
};

// Add prop to object
export const addProp = (key, value) => (item) => {
  item[key] = value;
  return item;
};
