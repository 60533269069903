
/* eslint-disable no-console */
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { getColor, getGist, getMDI } from '~/utils/helpers'; // , logger

export default {
  name: 'NodeTree',

  data() {
    return {
      open: [],
      active: [],
      previous: [],
      filterList: {},
      lastLoc: null,
      search: ''
    };
  },

  computed: {
    ...mapGetters({
      user: 'getIdentity',
      locList: 'location/getList',
      currentOrg: 'organization/getCurrent',
      currentLoc: 'location/getCurrent',
      isShared: 'acl/isNodeShared'
    }),

    currentPage() {
      return this.$route.path;
    },

    routeMap() {
      return {
        node: 'node',
        device: 'device'
      };
    },

    isActive() {
      return this.currentPage === '/node' || this.currentPage === '/device';
    },

    isFilter() {
      return Object.values(this.filterList).some((f) => f.value);
    },

    filteredTree() {
      if (this.isFilter) {
        const tree = JSON.parse(JSON.stringify(this.locList));
        return this.filterTree(tree);
      } else return this.locList;
    },

    filter() {
      return (item, search) => {
        const keys = ['name', 'id'];
        return keys.some((key) =>
          item[key].toLowerCase().includes(search.toLowerCase())
        );
      };
    }
  },

  watch: {
    open() {
      this.saveTreeOpen();
    },

    currentPage(value) {
      // logger(':{ WATCH }:', 'currentPage', value);
    },

    filteredTree() {
      if (this.previous[0]) {
        this.active = [...this.previous];
      }
      if (this.active[0]) {
        this.openTree(this.active[0]);
      }
    },

    isActive(value) {
      if (!value) this.active = [];
    },

    active(active) {
      if (!this.isActive && !active.length) return;
      // logger(':{ WATCH }:', 'active', active[0]);
      this.findLocById(active[0]);

      if (this.currentPage !== `/${this.currentLoc.type}`) {
        const desiredRoute = this.routeMap[this.currentLoc.type] || '/';
        this.$router.push(this.localePath(desiredRoute));
      }
    },

    locList() {
      // logger(':{ WATCH }:', 'LOC LIST', this.locList);
      this.loadTreePick();
      this.loadTreeOpen();
    },

    currentLoc() {
      if (!this.currentLoc.id) return;
      if (this.currentLoc.id === this.lastLoc?.id) return;
      const locId = this.currentLoc.id;
      // logger(':{ WATCH }:', 'CURRENT LOC', locId);

      this.$set(this.active, 0, locId);

      this.saveTreePick();
      this.openTree(locId);

      this.lastLoc = this.currentLoc;
    }
  },

  mounted() {
    console.log('* * * * * *    TREE MOUNTED    * * * * * *');

    this.filterList = {
      ONLINE: { value: false, separ: true },
      VIRTUAL: { value: false, separ: false },
      OFFLINE: { value: false, separ: false },
      UNKNOWN: { value: false, separ: false },
      SHARED: { value: false, separ: true },
      NODE: { value: false, separ: true }
    };
  },

  methods: {
    ...mapActions({
      findLocById: 'location/find',
      infoLocById: 'location/info',
      filterById: 'location/filter'
    }),
    ...mapMutations({
      setCurrentLoc: 'location/setCurrent'
    }),

    getMDI(value) {
      return getMDI(value);
    },
    getColor(value) {
      return getColor(value);
    },

    filterTree(tree) {
      return tree.filter((node) => {
        const status = getGist(node);

        const filterMatches = Object.entries(this.filterList).some(
          ([key, f]) => {
            if (key === 'SHARED') return f.value && this.isShared(node.id);
            return f.value && status === key;
          }
        );

        let childrenMatch = false;
        if (node.children) {
          node.children = this.filterTree(node.children);
          childrenMatch = node.children.length > 0;
        }

        if (filterMatches || childrenMatch) return true;
        return false;
      });
    },

    openTree(id) {
      const parts = id.split('/');
      let currentPath = parts[0];
      this.open.push(currentPath);
      for (let i = 1; i < parts.length - 1; i++) {
        currentPath += '/' + parts[i];
        this.open.push(currentPath);
      }
    },

    setActive(next) {
      const nextId = next[0] || this.lastLoc.id;
      if (this.previous[0] !== nextId) {
        this.previous = [nextId];
      }
    },

    // Збереження конфігурації дерева в localStorage
    saveTreeOpen() {
      if (!this.currentOrg.id || !this.active.length) return;
      const nameOpen = `${this.user.ID}.${this.currentOrg.id}.open`;
      const stringOpen = JSON.stringify(this.open);
      localStorage.setItem(nameOpen, stringOpen);
      // logger('>> >> >> >>', 'SAVE OPEN', stringOpen);
    },
    // Завантаження конфігурації дерева з localStorage
    loadTreeOpen() {
      if (!this.currentOrg.id) return;
      const nameOpen = `${this.user.ID}.${this.currentOrg.id}.open`;
      const open = localStorage.getItem(nameOpen);
      if (open) {
        try {
          const parsed = JSON.parse(open);
          this.open = parsed;
          // logger('<< << << <<', 'LOAD OPEN', parsed);
        } catch (e) {
          localStorage.removeItem(nameOpen);
        }
      }
    },

    // Збереження обраного вузла дерева в localStorage
    saveTreePick() {
      if (!this.currentOrg.id) return;
      const namePick = `${this.user.ID}.${this.currentOrg.id}.pick`;
      const arrayPick = [this.currentLoc.id];
      const stringPick = JSON.stringify(arrayPick);
      localStorage.setItem(namePick, stringPick);
      // logger('> > > > > >', 'SAVE PICK', stringPick);
    },
    // Завантаження обраного вузла дерева з localStorage
    loadTreePick() {
      if (!this.currentOrg.id) return;
      const namePick = `${this.user.ID}.${this.currentOrg.id}.pick`;
      const pick = localStorage.getItem(namePick);
      if (pick) {
        try {
          const parsed = JSON.parse(pick);
          // logger('< < < < < <', 'LOAD PICK', parsed);
          const [node] = parsed;
          if (node !== this.lastLoc?.id) {
            this.findLocById(node);
          }
        } catch (e) {
          localStorage.removeItem(namePick);
        }
      } else {
        this.setCurrentLoc(this.locList[0]);
      }
    }
  }
};
