
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { filterArray, logger } from '~/utils/helpers';

export default {
  name: 'GroupList',

  data() {
    return {
      search: '',
      prevOrg: '',
      dialog: false,
      isDelOrg: false,
      isAddOrg: false,
      isEditOrg: false,
      currentGroup: null,
      hideSearchInput: true
    };
  },

  computed: {
    ...mapGetters({
      user: 'getIdentity',
      orgList: 'organization/getList',
      currentOrg: 'organization/getCurrent'
    }),

    filteredList() {
      return filterArray(this.orgList, this.search);
    }
  },

  watch: {
    async user(user) {
      if (user.ID) await this.loadOrgsList();
    },

    orgList() {
      this.loadOrgSettings();

      if (this.orgList.length === 0) {
        this.setCurrentOrg({});
        this.setLocList([]);
        this.dialog = true;
      }
    },

    async currentOrg(org) {
      if (!org) return;
      if (org.id !== this.prevOrg) this.saveOrgSettings(org);
      await this.loadWidgets();
      await this.loadACLList(org.id);
      await this.loadLocList(org.id);
      this.loadBrokers(org.id);
      //
      // await this.loadRuleTypes(org.id);
    }
  },

  methods: {
    ...mapActions({
      loadOrgsList: 'organization/loadList',
      loadWidgets: 'dashboard/loadWidgets',
      loadBrokers: 'device/loadBrokers',
      loadLocList: 'location/loadList',
      loadACLList: 'acl/list'
      // loadRuleTypes: 'rule/loadRuleTypes'
    }),
    ...mapMutations({
      setCurrentOrg: 'organization/setCurrent',
      setLocList: 'location/setList'
    }),

    close() {
      this.dialog = false;
    },

    toggleSearch() {
      this.hideSearchInput = !this.hideSearchInput;
    },

    select(org) {
      this.setCurrentOrg(org);
      this.dialog = false;
    },

    selectById(id) {
      return this.orgList.find((el) => el.id === id);
    },

    addOrgOn() {
      this.isAddOrg = true;
    },
    addOrgOff() {
      this.isAddOrg = false;
    },

    editOrgOn(group) {
      this.currentGroup = group;
      this.isEditOrg = true;
    },
    editOrgOff() {
      this.currentGroup = null;
      this.isEditOrg = false;
    },

    delOrgOn(group) {
      this.currentGroup = group;
      this.isDelOrg = true;
    },
    delOrgOff() {
      this.currentGroup = null;
      this.isDelOrg = false;
    },

    response(value) {
      logger('<G R O U P>', 'RESPONSE', value);
    },

    // Збереження параметрів організації в localStorage
    saveOrgSettings(org) {
      if (!this.user.ID) return;
      const settingName = `${this.user.ID}.org`;
      if (org.id) {
        const stringified = JSON.stringify(org.id);
        localStorage.setItem(settingName, stringified);
        // logger('>>>>> >>>>>', 'SAVE ORG', stringified);
      } else {
        localStorage.removeItem(settingName);
      }
    },
    // Завантаження параметрів організації з localStorage
    loadOrgSettings() {
      // logger('<<<<< <<<<<', 'USER ID', this.user.ID);
      if (!this.user.ID) return;
      const settingName = `${this.user.ID}.org`;
      const loaded = localStorage.getItem(settingName);
      if (loaded) {
        try {
          const parsed = JSON.parse(loaded);
          const org = this.selectById(parsed);
          // logger('<<<<< <<<<<', 'LOAD ORG', org.id);
          this.prevOrg = org.id;
          this.setCurrentOrg(org);
        } catch (e) {
          localStorage.removeItem(settingName);
        }
      }
    }
  }
};
