
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ProfileMenu',

  computed: {
    ...mapGetters({
      identity: 'getIdentity'
    }),

    userInitials() {
      const initials =
        this.identity.first_name.charAt(0) + this.identity.last_name.charAt(0);
      return initials.toUpperCase() || '';
    },
    userFullName() {
      return this.identity.first_name + ' ' + this.identity.last_name;
    }
  },

  created() {
    this.loadIdentityInfo();
  },

  methods: {
    ...mapActions({
      loadIdentityInfo: 'loadIdentityInfo'
    }),

    activityLog() {
      this.$router.push(this.localePath('activityLog'));
    },

    logout() {
      this.$store.dispatch('logout');
    }
  }
};
