
export default {
  name: 'Dialog',

  props: {
    enable: Boolean,
    max: {
      type: String,
      default: '400px'
    }
  },

  data() {
    return {
      dialog: false
    };
  },

  watch: {
    enable() {
      this.dialog = this.enable;
    }
  },

  methods: {
    close() {
      this.$emit('close');
    }
  }
};
