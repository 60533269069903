export default ($axios) => ({
  async index(params) {
    try {
      return await $axios.$get('/v1/me/journal1', {
        params
      });
    } catch (e) {
      // throw e;
      return { e };
    }
  }
});
